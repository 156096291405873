import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// components
import { Container } from '../../components/container/Container';

// props
import { ContactSummaryProps } from './ContactSummaryProps';

// styles
import * as styles from './ContactSummary.sass';

// template
export class ContactSummary extends Component<ContactSummaryProps, {}> {
  render() {
    return (
      <div className={classNames(styles.main, this.props.className)}>
        <Container className={styles.contactSummary}>
          <div className={styles.contactSummaryTitle}>Call for Quote</div>
          <div className={styles.orangeBar} />
          <p>
            Getting a quote is as easy and picking up the phone and letting us
            know what you need. We are locally owned and operated and focus
            predominately on the South Texas region which means we are usually
            quicker to get a hold of and faster to respond than the national
            companies.
          </p>
          <p>
            For after hours emergencies only, call{' '}
            <a href="tel:361-877-3231">361-877-3231</a> /{' '}
            <a href="tel:361-885-5425">361-885-5425</a>.
          </p>
        </Container>
      </div>
    );
  }
}
