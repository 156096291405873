import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// components
import { Container } from '../container/Container';
import { SignsStart } from '../signs/SignsStart';

// props
import { HeroProps } from './HeroProps';

// styles
import * as styles from './Hero.sass';

// template
export class Hero extends Component<HeroProps, {}> {
  videoContainer: HTMLDivElement;
  componentDidMount() {
    const video = document.createElement('video');
    video.className = styles.video;
    video.autoplay = true;
    video.muted = true;
    video.loop = true;
    video.setAttribute('playsinline', 'true');

    const source = document.createElement('source');
    source.src = 'assets/cars.mp4';
    source.type = 'video/mp4';
    video.appendChild(source);

    this.videoContainer.appendChild(video);
  }
  render() {
    return (
      <div className={classNames(styles.hero, this.props.className)}>
        <div className={styles.heroContent}>
          <div className={styles.bg} ref={(ref) => { this.videoContainer = ref; }} />
          <Container className={styles.contentContainer}>
            <div className={styles.left}>
              <div className={styles.heroTitle}>
                <div>
                  SERVING SOUTH TEXAS
                  <br />
                  SINCE 2009
                  <br />
                </div>
              </div>
              <div className={styles.heroSubtitle}>
                Highway Barricades and Services (HBS) is one of the premier
                woman-owned businesses in South Texas.
              </div>
              <div className={styles.heroButtonContainer}>
                <a href="/services" className={styles.heroButton}>
                  VIEW SERVICES
                </a>
                <img className={styles.logo} src="assets/dbe-logo.svg" />
              </div>
            </div>
            <div className={styles.right}>
              <SignsStart />
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
