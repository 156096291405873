/*
 * this file is automatically generated for marvelous via zappjs
 * any manual changes to this file will be overwritten with the "mvs" command
 */

import * as http from 'http';
import * as url from 'url';

interface IClientOpts {
  url: string;
}

interface IClient {
  createMessage: IClientCreateMessageFunction;
  createRecipient: IClientCreateRecipientFunction;
  deleteMessage: IClientDeleteMessageFunction;
  deleteRecipient: IClientDeleteRecipientFunction;
  geocodeLocation: IClientGeocodeLocationFunction;
  getMessage: IClientGetMessageFunction;
  getRecipient: IClientGetRecipientFunction;
  listMessages: IClientListMessagesFunction;
  listRecipients: IClientListRecipientsFunction;
  updateRecipient: IClientUpdateRecipientFunction;
}

export interface IClientCreateMessageFunction {
  (request?: IClientCreateMessageRequest): Promise<IClientCreateMessageResponse>;
}

export interface IClientCreateMessageRequest {
  recipientId: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}

export interface IClientCreateMessageResponse {
  id: string;
}

export interface IClientCreateRecipientFunction {
  (request?: IClientCreateRecipientRequest): Promise<IClientCreateRecipientResponse>;
}

export interface IClientCreateRecipientRequest {
  name: string;
  email: string;
}

export interface IClientCreateRecipientResponse {
  id: string;
}

export interface IClientDeleteMessageFunction {
  (request?: IClientDeleteMessageRequest): Promise<IClientDeleteMessageResponse>;
}

export interface IClientDeleteMessageRequest {
  id: string;
}

export interface IClientDeleteMessageResponse {
}

export interface IClientDeleteRecipientFunction {
  (request?: IClientDeleteRecipientRequest): Promise<IClientDeleteRecipientResponse>;
}

export interface IClientDeleteRecipientRequest {
  id: string;
}

export interface IClientDeleteRecipientResponse {
}

export interface IClientGeocodeLocationFunction {
  (request?: IClientGeocodeLocationRequest): Promise<IClientGeocodeLocationResponse>;
}

export interface IClientGeocodeLocationRequest {
  address: string;
}

export interface IClientGeocodeLocationResponse {
  address: string;
  latitude: number;
  longitude: number;
}

export interface IClientGetMessageFunction {
  (request?: IClientGetMessageRequest): Promise<IClientGetMessageResponse>;
}

export interface IClientGetMessageRequest {
  id: string;
}

export interface IClientGetMessageResponse {
  id: string;
  recipientId: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}

export interface IClientGetRecipientFunction {
  (request?: IClientGetRecipientRequest): Promise<IClientGetRecipientResponse>;
}

export interface IClientGetRecipientRequest {
  id: string;
}

export interface IClientGetRecipientResponse {
  _id: string;
  _createdAt: number;
  name: string;
  email: string;
}

export interface IClientListMessagesFunction {
  (request?: IClientListMessagesRequest): Promise<IClientListMessagesResponse>;
}

export interface IClientListMessagesRequest {
}

export interface IClientListMessagesResponse {
  messages: any[];
}

export interface IClientListRecipientsFunction {
  (request?: IClientListRecipientsRequest): Promise<IClientListRecipientsResponse>;
}

export interface IClientListRecipientsRequest {
}

export interface IClientListRecipientsResponse {
  recipients: any[];
}

export interface IClientUpdateRecipientFunction {
  (request?: IClientUpdateRecipientRequest): Promise<IClientUpdateRecipientResponse>;
}

export interface IClientUpdateRecipientRequest {
  id: string;
  data?: any;
}

export interface IClientUpdateRecipientResponse {
}


async function handler(req: any = {}, urlString: string = ''): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      const reqString = JSON.stringify(req);
      const urlParts = url.parse(urlString);
      
      const input = http.request(
        {
          protocol: urlParts.protocol,
          host: urlParts.hostname,
          port: urlParts.port,
          path: urlParts.pathname,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Content-Length': Buffer.byteLength(reqString)
          }
        },
        output => {
          let responseString = '';

          output.on('data', chunk => {
            responseString += chunk;
          });

          output.on('end', () => {
            const response = JSON.parse(responseString) || {};
            if (output.statusCode === 200) {
              resolve(response);
            } else {
              reject(response);
            }
          });
        }
      );
      input.on('error', reject);
      input.write(reqString);
      input.end();
    } catch (error) {
      reject(error);
    }
  });
}

export class Client implements IClient {
  url = 'http://localhost:5000';

  constructor(opts?: IClientOpts) {
    if (opts && opts.url !== undefined) {
      this.url = opts.url;
    }
  }

  createMessage: IClientCreateMessageFunction = async req => {
    return handler(req, `${this.url}/createMessage`);
  };

  createRecipient: IClientCreateRecipientFunction = async req => {
    return handler(req, `${this.url}/createRecipient`);
  };

  deleteMessage: IClientDeleteMessageFunction = async req => {
    return handler(req, `${this.url}/deleteMessage`);
  };

  deleteRecipient: IClientDeleteRecipientFunction = async req => {
    return handler(req, `${this.url}/deleteRecipient`);
  };

  geocodeLocation: IClientGeocodeLocationFunction = async req => {
    return handler(req, `${this.url}/geocodeLocation`);
  };

  getMessage: IClientGetMessageFunction = async req => {
    return handler(req, `${this.url}/getMessage`);
  };

  getRecipient: IClientGetRecipientFunction = async req => {
    return handler(req, `${this.url}/getRecipient`);
  };

  listMessages: IClientListMessagesFunction = async req => {
    return handler(req, `${this.url}/listMessages`);
  };

  listRecipients: IClientListRecipientsFunction = async req => {
    return handler(req, `${this.url}/listRecipients`);
  };

  updateRecipient: IClientUpdateRecipientFunction = async req => {
    return handler(req, `${this.url}/updateRecipient`);
  };
}
