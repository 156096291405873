import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import { Fade } from 'react-reveal';

// components
import { Container } from '../../components/container/Container';

// props
import { AboutProps } from './AboutProps';

// styles
import * as styles from './About.sass';

// template
export class About extends Component<AboutProps, {}> {
  render() {
    return (
      <div className={classNames(styles.main, this.props.className)}>
        <Container className={styles.aboutContainer}>
          <Fade left>
            <div className={styles.aboutPhoto} />
          </Fade>
          <div className={styles.aboutContentContainer}>
            <Fade right>
              <div>
                <div className={styles.aboutTitle}>Our Promise</div>
                <div className={styles.orangeBar} />
              </div>
            </Fade>
            <Fade right>
              <p className={styles.aboutDescription}>
                For a decade, Highway Barricades and Services has aided in the
                infrastructure growth of the coastal bend. We are always
                interested in customer feedback and strive to be as flexible and
                turnkey as possible while offering competitive pricing. Give us
                a call for a free estimate on your next project or special
                event.
              </p>
            </Fade>
            <Fade cascade right delay={400}>
              <div className={styles.blurbs}>
                <div
                  className={classNames(
                    styles.blurbContainer,
                    styles.blurbContainerCenter
                  )}
                >
                  <img
                    className={styles.aboutIcon}
                    src="assets/about-lightbulb.svg"
                  />

                  <div className={styles.blurbText}>
                    <h2 className={styles.blurbTitle}>
                      Best Equipment - For Sale or Rent
                    </h2>
                    <div className={styles.aboutDescription}>
                      HBS is the South Texas distributor of TrafFix Devices&reg;
                      Attenuators, Solar Technology&trade; and Wanco&reg; Message/Arrow Boards. We maintain a
                      hefty inventory of traffic control equipment and can also
                      order specialty related accessories and bulk traffic
                      control items.
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.blurbContainer,
                    styles.blurbContainerCenter
                  )}
                >
                  <img
                    className={styles.aboutIcon}
                    src="assets/about-cone.svg"
                  />

                  <div className={styles.blurbText}>
                    <h2 className={styles.blurbTitle}>Serious about Safety</h2>
                    <div className={styles.aboutDescription}>
                      Just as our customers rely on our expertise to complete
                      their projects on schedule and within budget, they also
                      depend on us to maintain a safe working perimeter. Our
                      team is regularly trained and certified in traffic control
                      and flagger safety to ensure pedestrian and motorist
                      safety through the entirety of your project.
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.blurbContainer,
                    styles.blurbContainerEnd
                  )}
                >
                  <img
                    className={styles.aboutIcon}
                    src="assets/about-hardhat.svg"
                  />

                  <div className={styles.blurbText}>
                    <h2 className={styles.blurbTitle}>Experienced Staff</h2>
                    <div className={styles.aboutDescription}>
                      Our key personnel have over 25 years of experience in the
                      business. We can quickly turn around estimates and act
                      swiftly on emergency needs.
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </Container>
      </div>
    );
  }
}
