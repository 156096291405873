import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Component } from 'react';
import { Helmet } from 'react-helmet';

// actions
import {
  selectMenuItemAction,
  ISelectMenuItemActionParams,
  ISetBreadcrumbsActionParams,
  setBreadcrumbsAction
} from '../../actions';

// components
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { SpecialEvents } from '../../components/specialEvents/SpecialEvents';

// interfaces
import {
  ISpecialEventsPageOwnProps,
  ISpecialEventsPageStateProps,
  ISpecialEventsPageDispatchProps
} from './SpecialEventsPage.i';

// styles
import * as styles from './SpecialEventsPage.sass';

// props
type Props = ISpecialEventsPageOwnProps &
  ISpecialEventsPageStateProps &
  ISpecialEventsPageDispatchProps;

// component
class SpecialEventsPageComponent extends Component<Props, {}> {
  componentWillMount() {
    this.props.selectMenuItem({
      menuItem: 'events'
    });
    this.props.setBreadcrumbs({
      breadcrumbsItems: [
        { name: 'events', url: 'events', text: 'Special Events' }
      ]
    });
  }
  render() {
    return (
      <div className={classNames(styles.specialEvents, this.props.className)}>
        <Helmet>
          <title>Special Events</title>
          <meta
            name="description"
            content="Special Events - Highway Barricades &amp; Services"
          />
          <meta
            name="keywords"
            content="highway,barricades,services,special,events,race,celebration,traffic,control,equipment,fencing,planning"
          />
        </Helmet>
        <Breadcrumbs />
        <SpecialEvents />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectMenuItem: (params: ISelectMenuItemActionParams) => {
      dispatch(selectMenuItemAction(params));
    },
    setBreadcrumbs: (params: ISetBreadcrumbsActionParams) => {
      dispatch(setBreadcrumbsAction(params));
    }
  };
}

export const SpecialEventsPage = connect(
  null,
  mapDispatchToProps
)(SpecialEventsPageComponent);
