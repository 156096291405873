import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// components
import { Container } from '../../components/container/Container';

// props
import { MissionProps } from './MissionProps';

// styles
import * as styles from './Mission.sass';

// template
export class Mission extends Component<MissionProps, {}> {
  render() {
    return (
      <div className={classNames(styles.mission, this.props.className)}>
        <Container className={styles.missionContentContainer}>
          {/* <div className={styles.missionImageContainer}> */}
          <div className={styles.missionImage} />
          {/* </div> */}

          <div className={styles.missionText}>
            <div className={styles.missionTitle}>About Us</div>
            <div className={styles.orangeBar} />
            <p className={styles.missionDescription}>
              <i>Where safety meets satisfaction.</i>
            </p>
            <p className={styles.missionDescription}>
              Highway Barricades and Services is a H.U.B./D.B.E. certified
              Corpus Christi based, full service traffic control company that
              prides itself on being flexible for its customers. We offer
              traffic control planning, setup, maintenance and take down of
              traffic devices, as well as both sales and rental of our equipment
              inventory. In addition to traffic control, we provide permanent
              signs, trench safety and pavement marking services.
            </p>
            <p className={styles.missionDescription}>
              Established in 2009, HBS has grown to be the premier barricades
              and pavement markings service company in South Texas. With a
              strong presence in both Corpus Christi and the Rio Grande Valley
              region, we have been a part of the major infrastructure growth of
              South Texas and are committed to helping our community continue to
              grow.
            </p>
          </div>
        </Container>
      </div>
    );
  }
}
