export const SpecialEvents = {
  xOffset: 0,
  yOffset: 0,
  pixels: [
    {
      x: 7,
      y: 6
    },
    {
      x: 8,
      y: 5
    },
    {
      x: 9,
      y: 5
    },
    {
      x: 10,
      y: 5
    },
    {
      x: 11,
      y: 5
    },
    {
      x: 8,
      y: 6
    },
    {
      x: 7,
      y: 7
    },
    {
      x: 8,
      y: 7
    },
    {
      x: 8,
      y: 8
    },
    {
      x: 9,
      y: 8
    },
    {
      x: 10,
      y: 8
    },
    {
      x: 11,
      y: 8
    },
    {
      x: 11,
      y: 6
    },
    {
      x: 12,
      y: 6
    },
    {
      x: 11,
      y: 9
    },
    {
      x: 12,
      y: 9
    },
    {
      x: 11,
      y: 10
    },
    {
      x: 12,
      y: 10
    },
    {
      x: 11,
      y: 11
    },
    {
      x: 10,
      y: 11
    },
    {
      x: 9,
      y: 11
    },
    {
      x: 8,
      y: 11
    },
    {
      x: 7,
      y: 10
    },
    {
      x: 14,
      y: 5
    },
    {
      x: 14,
      y: 7
    },
    {
      x: 14,
      y: 6
    },
    {
      x: 14,
      y: 8
    },
    {
      x: 14,
      y: 9
    },
    {
      x: 14,
      y: 10
    },
    {
      x: 14,
      y: 11
    },
    {
      x: 15,
      y: 5
    },
    {
      x: 15,
      y: 6
    },
    {
      x: 15,
      y: 7
    },
    {
      x: 15,
      y: 8
    },
    {
      x: 15,
      y: 9
    },
    {
      x: 15,
      y: 10
    },
    {
      x: 15,
      y: 11
    },
    {
      x: 16,
      y: 5
    },
    {
      x: 17,
      y: 5
    },
    {
      x: 18,
      y: 5
    },
    {
      x: 18,
      y: 6
    },
    {
      x: 19,
      y: 6
    },
    {
      x: 18,
      y: 7
    },
    {
      x: 19,
      y: 7
    },
    {
      x: 16,
      y: 8
    },
    {
      x: 17,
      y: 8
    },
    {
      x: 18,
      y: 8
    },
    {
      x: 21,
      y: 5
    },
    {
      x: 21,
      y: 7
    },
    {
      x: 21,
      y: 6
    },
    {
      x: 21,
      y: 8
    },
    {
      x: 21,
      y: 9
    },
    {
      x: 21,
      y: 10
    },
    {
      x: 21,
      y: 11
    },
    {
      x: 22,
      y: 5
    },
    {
      x: 22,
      y: 6
    },
    {
      x: 22,
      y: 7
    },
    {
      x: 22,
      y: 8
    },
    {
      x: 22,
      y: 9
    },
    {
      x: 22,
      y: 10
    },
    {
      x: 22,
      y: 11
    },
    {
      x: 23,
      y: 5
    },
    {
      x: 24,
      y: 5
    },
    {
      x: 25,
      y: 5
    },
    {
      x: 26,
      y: 5
    },
    {
      x: 23,
      y: 8
    },
    {
      x: 24,
      y: 8
    },
    {
      x: 25,
      y: 8
    },
    {
      x: 23,
      y: 11
    },
    {
      x: 24,
      y: 11
    },
    {
      x: 25,
      y: 11
    },
    {
      x: 26,
      y: 11
    },
    {
      x: 28,
      y: 6
    },
    {
      x: 28,
      y: 7
    },
    {
      x: 28,
      y: 9
    },
    {
      x: 28,
      y: 8
    },
    {
      x: 28,
      y: 10
    },
    {
      x: 29,
      y: 11
    },
    {
      x: 30,
      y: 11
    },
    {
      x: 31,
      y: 11
    },
    {
      x: 32,
      y: 11
    },
    {
      x: 29,
      y: 10
    },
    {
      x: 29,
      y: 9
    },
    {
      x: 29,
      y: 8
    },
    {
      x: 29,
      y: 7
    },
    {
      x: 29,
      y: 6
    },
    {
      x: 29,
      y: 5
    },
    {
      x: 30,
      y: 5
    },
    {
      x: 31,
      y: 5
    },
    {
      x: 32,
      y: 5
    },
    {
      x: 32,
      y: 6
    },
    {
      x: 33,
      y: 6
    },
    {
      x: 32,
      y: 10
    },
    {
      x: 33,
      y: 10
    },
    {
      x: 35,
      y: 5
    },
    {
      x: 36,
      y: 5
    },
    {
      x: 37,
      y: 5
    },
    {
      x: 38,
      y: 5
    },
    {
      x: 36,
      y: 6
    },
    {
      x: 37,
      y: 6
    },
    {
      x: 36,
      y: 7
    },
    {
      x: 37,
      y: 7
    },
    {
      x: 36,
      y: 8
    },
    {
      x: 37,
      y: 8
    },
    {
      x: 36,
      y: 9
    },
    {
      x: 37,
      y: 9
    },
    {
      x: 36,
      y: 10
    },
    {
      x: 37,
      y: 10
    },
    {
      x: 36,
      y: 11
    },
    {
      x: 37,
      y: 11
    },
    {
      x: 35,
      y: 11
    },
    {
      x: 38,
      y: 11
    },
    {
      x: 40,
      y: 7
    },
    {
      x: 40,
      y: 8
    },
    {
      x: 40,
      y: 9
    },
    {
      x: 40,
      y: 10
    },
    {
      x: 40,
      y: 11
    },
    {
      x: 41,
      y: 11
    },
    {
      x: 41,
      y: 10
    },
    {
      x: 41,
      y: 9
    },
    {
      x: 41,
      y: 8
    },
    {
      x: 41,
      y: 7
    },
    {
      x: 41,
      y: 6
    },
    {
      x: 42,
      y: 5
    },
    {
      x: 43,
      y: 5
    },
    {
      x: 44,
      y: 6
    },
    {
      x: 44,
      y: 8
    },
    {
      x: 44,
      y: 7
    },
    {
      x: 44,
      y: 9
    },
    {
      x: 44,
      y: 10
    },
    {
      x: 44,
      y: 11
    },
    {
      x: 45,
      y: 11
    },
    {
      x: 45,
      y: 10
    },
    {
      x: 45,
      y: 9
    },
    {
      x: 45,
      y: 8
    },
    {
      x: 45,
      y: 7
    },
    {
      x: 42,
      y: 9
    },
    {
      x: 43,
      y: 9
    },
    {
      x: 47,
      y: 5
    },
    {
      x: 47,
      y: 6
    },
    {
      x: 47,
      y: 7
    },
    {
      x: 47,
      y: 8
    },
    {
      x: 47,
      y: 9
    },
    {
      x: 47,
      y: 10
    },
    {
      x: 47,
      y: 11
    },
    {
      x: 48,
      y: 11
    },
    {
      x: 48,
      y: 10
    },
    {
      x: 48,
      y: 9
    },
    {
      x: 48,
      y: 8
    },
    {
      x: 48,
      y: 7
    },
    {
      x: 48,
      y: 6
    },
    {
      x: 48,
      y: 5
    },
    {
      x: 49,
      y: 11
    },
    {
      x: 50,
      y: 11
    },
    {
      x: 51,
      y: 11
    },
    {
      x: 52,
      y: 11
    },
    {
      x: 8,
      y: 24
    },
    {
      x: 8,
      y: 23
    },
    {
      x: 8,
      y: 22
    },
    {
      x: 8,
      y: 21
    },
    {
      x: 8,
      y: 20
    },
    {
      x: 8,
      y: 19
    },
    {
      x: 8,
      y: 18
    },
    {
      x: 9,
      y: 18
    },
    {
      x: 9,
      y: 19
    },
    {
      x: 9,
      y: 20
    },
    {
      x: 9,
      y: 21
    },
    {
      x: 9,
      y: 22
    },
    {
      x: 9,
      y: 23
    },
    {
      x: 9,
      y: 24
    },
    {
      x: 10,
      y: 18
    },
    {
      x: 11,
      y: 18
    },
    {
      x: 12,
      y: 18
    },
    {
      x: 13,
      y: 18
    },
    {
      x: 10,
      y: 24
    },
    {
      x: 11,
      y: 24
    },
    {
      x: 12,
      y: 24
    },
    {
      x: 13,
      y: 24
    },
    {
      x: 10,
      y: 21
    },
    {
      x: 11,
      y: 21
    },
    {
      x: 12,
      y: 21
    },
    {
      x: 15,
      y: 18
    },
    {
      x: 15,
      y: 19
    },
    {
      x: 15,
      y: 20
    },
    {
      x: 16,
      y: 18
    },
    {
      x: 16,
      y: 19
    },
    {
      x: 16,
      y: 20
    },
    {
      x: 16,
      y: 21
    },
    {
      x: 16,
      y: 22
    },
    {
      x: 17,
      y: 23
    },
    {
      x: 17,
      y: 24
    },
    {
      x: 18,
      y: 24
    },
    {
      x: 18,
      y: 23
    },
    {
      x: 19,
      y: 22
    },
    {
      x: 19,
      y: 21
    },
    {
      x: 19,
      y: 20
    },
    {
      x: 19,
      y: 19
    },
    {
      x: 19,
      y: 18
    },
    {
      x: 20,
      y: 18
    },
    {
      x: 20,
      y: 19
    },
    {
      x: 20,
      y: 20
    },
    {
      x: 22,
      y: 18
    },
    {
      x: 22,
      y: 19
    },
    {
      x: 22,
      y: 20
    },
    {
      x: 22,
      y: 21
    },
    {
      x: 22,
      y: 22
    },
    {
      x: 22,
      y: 23
    },
    {
      x: 22,
      y: 24
    },
    {
      x: 23,
      y: 24
    },
    {
      x: 23,
      y: 23
    },
    {
      x: 23,
      y: 22
    },
    {
      x: 23,
      y: 21
    },
    {
      x: 23,
      y: 20
    },
    {
      x: 23,
      y: 19
    },
    {
      x: 23,
      y: 18
    },
    {
      x: 24,
      y: 18
    },
    {
      x: 25,
      y: 18
    },
    {
      x: 26,
      y: 18
    },
    {
      x: 27,
      y: 18
    },
    {
      x: 24,
      y: 21
    },
    {
      x: 25,
      y: 21
    },
    {
      x: 26,
      y: 21
    },
    {
      x: 24,
      y: 24
    },
    {
      x: 25,
      y: 24
    },
    {
      x: 26,
      y: 24
    },
    {
      x: 27,
      y: 24
    },
    {
      x: 29,
      y: 24
    },
    {
      x: 29,
      y: 23
    },
    {
      x: 29,
      y: 22
    },
    {
      x: 29,
      y: 21
    },
    {
      x: 29,
      y: 20
    },
    {
      x: 29,
      y: 19
    },
    {
      x: 29,
      y: 18
    },
    {
      x: 30,
      y: 18
    },
    {
      x: 30,
      y: 19
    },
    {
      x: 30,
      y: 21
    },
    {
      x: 30,
      y: 20
    },
    {
      x: 30,
      y: 22
    },
    {
      x: 30,
      y: 23
    },
    {
      x: 30,
      y: 24
    },
    {
      x: 33,
      y: 18
    },
    {
      x: 33,
      y: 19
    },
    {
      x: 33,
      y: 20
    },
    {
      x: 33,
      y: 21
    },
    {
      x: 33,
      y: 22
    },
    {
      x: 33,
      y: 23
    },
    {
      x: 33,
      y: 24
    },
    {
      x: 34,
      y: 24
    },
    {
      x: 34,
      y: 23
    },
    {
      x: 34,
      y: 22
    },
    {
      x: 34,
      y: 21
    },
    {
      x: 34,
      y: 20
    },
    {
      x: 34,
      y: 19
    },
    {
      x: 34,
      y: 18
    },
    {
      x: 31,
      y: 20
    },
    {
      x: 32,
      y: 21
    },
    {
      x: 36,
      y: 18
    },
    {
      x: 37,
      y: 18
    },
    {
      x: 38,
      y: 18
    },
    {
      x: 39,
      y: 18
    },
    {
      x: 40,
      y: 18
    },
    {
      x: 41,
      y: 18
    },
    {
      x: 38,
      y: 19
    },
    {
      x: 38,
      y: 20
    },
    {
      x: 38,
      y: 21
    },
    {
      x: 38,
      y: 22
    },
    {
      x: 38,
      y: 23
    },
    {
      x: 38,
      y: 24
    },
    {
      x: 39,
      y: 24
    },
    {
      x: 39,
      y: 23
    },
    {
      x: 39,
      y: 22
    },
    {
      x: 39,
      y: 21
    },
    {
      x: 39,
      y: 20
    },
    {
      x: 39,
      y: 19
    },
    {
      x: 43,
      y: 19
    },
    {
      x: 43,
      y: 20
    },
    {
      x: 44,
      y: 18
    },
    {
      x: 45,
      y: 18
    },
    {
      x: 46,
      y: 18
    },
    {
      x: 47,
      y: 18
    },
    {
      x: 44,
      y: 19
    },
    {
      x: 44,
      y: 20
    },
    {
      x: 44,
      y: 21
    },
    {
      x: 45,
      y: 21
    },
    {
      x: 46,
      y: 21
    },
    {
      x: 47,
      y: 21
    },
    {
      x: 47,
      y: 19
    },
    {
      x: 48,
      y: 19
    },
    {
      x: 47,
      y: 22
    },
    {
      x: 47,
      y: 23
    },
    {
      x: 47,
      y: 24
    },
    {
      x: 48,
      y: 23
    },
    {
      x: 48,
      y: 22
    },
    {
      x: 46,
      y: 24
    },
    {
      x: 45,
      y: 24
    },
    {
      x: 44,
      y: 24
    },
    {
      x: 43,
      y: 23
    },
    {
      x: 44,
      y: 23
    },
    {
      x: 8,
      y: 10
    }
  ]
};
