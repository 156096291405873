// actions
import { ISetBreadcrumbsAction } from '../../actions';

// constants
import { SET_BREADCRUMBS } from '../../constants';

import { IBreadcrumbsReducerState } from './breadcrumbsReducer.i';

const initialState: IBreadcrumbsReducerState = {
  breadcrumbsItems: []
};

export function breadcrumbsReducer(
  state = initialState,
  action: ISetBreadcrumbsAction
): IBreadcrumbsReducerState {
  switch (action.type) {
    case SET_BREADCRUMBS: {
      return {
        ...state,
        breadcrumbsItems: action.payload.breadcrumbsItems
      };
    }
    default: {
      return state;
    }
  }
}
