// actions
import { ISelectMenuItemAction } from '../../actions';

// constants
import { SELECT_MENU_ITEM } from '../../constants';

import { IMenuReducerState } from './menuReducer.i';

const initialState: IMenuReducerState = {
  menuItems: [
    {
      name: 'home',
      text: 'Home',
      url: '/'
    },
    {
      name: 'about',
      text: 'About',
      url: '/about'
    },
    {
      name: 'services',
      text: 'Services',
      url: '/services'
    },
    {
      name: 'equipment',
      text: 'Equipment',
      url: '/equipment'
    },
    {
      name: 'events',
      text: 'Special Events',
      url: '/events'
    }
  ],
  selectedMenuItem: 'home'
};

export function menuReducer(
  state = initialState,
  action: ISelectMenuItemAction
): IMenuReducerState {
  switch (action.type) {
    case SELECT_MENU_ITEM: {
      return {
        ...state,
        selectedMenuItem: action.payload.menuItem
      };
    }
    default: {
      return state;
    }
  }
}
