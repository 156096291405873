// constants
import { SELECT_SERVICES } from '../../constants';

// interfaces
import {
  ISelectServicesAction,
  ISelectServicesActionParams
} from './selectServicesAction.i';

export function selectServicesAction(
  params: ISelectServicesActionParams
): ISelectServicesAction {
  return {
    type: SELECT_SERVICES,
    payload: {
      services: params.services
    }
  };
}
