import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Component } from 'react';
import { Helmet } from 'react-helmet';

// actions
import {
  selectMenuItemAction,
  ISelectMenuItemActionParams,
  ISetBreadcrumbsActionParams,
  setBreadcrumbsAction
} from '../../actions';

// components
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Services } from '../../components/services/Services';

// interfaces
import {
  IServicesPageOwnProps,
  IServicesPageStateProps,
  IServicesPageDispatchProps
} from './ServicesPage.i';

// styles
import * as styles from './ServicesPage.sass';

// props
type Props = IServicesPageOwnProps &
  IServicesPageStateProps &
  IServicesPageDispatchProps;

// component
class ServicesPageComponent extends Component<Props, {}> {
  componentWillMount() {
    this.props.selectMenuItem({
      menuItem: 'services'
    });
    this.props.setBreadcrumbs({
      breadcrumbsItems: [
        { name: 'services', url: 'services', text: 'Services' }
      ]
    });
  }
  render() {
    return (
      <div className={classNames(styles.services, this.props.className)}>
        <Helmet>
          <title>Services</title>
          <meta
            name="description"
            content="Services - Highway Barricades &amp; Services"
          />
          <meta
            name="keywords"
            content="highway,barricades,services,traffic,control,signs,trench,safety,pavement,markings,training"
          />
        </Helmet>
        <Breadcrumbs />
        <Services />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectMenuItem: (params: ISelectMenuItemActionParams) => {
      dispatch(selectMenuItemAction(params));
    },
    setBreadcrumbs: (params: ISetBreadcrumbsActionParams) => {
      dispatch(setBreadcrumbsAction(params));
    }
  };
}

export const ServicesPage = connect(
  null,
  mapDispatchToProps
)(ServicesPageComponent);
