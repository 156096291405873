export const Signs = {
  xOffset: 0,
  yOffset: 0,
  pixels: [
    {
      x: 13,
      y: 14
    },
    {
      x: 14,
      y: 14
    },
    {
      x: 14,
      y: 15
    },
    {
      x: 15,
      y: 15
    },
    {
      x: 15,
      y: 16
    },
    {
      x: 16,
      y: 16
    },
    {
      x: 15,
      y: 17
    },
    {
      x: 16,
      y: 17
    },
    {
      x: 15,
      y: 18
    },
    {
      x: 16,
      y: 18
    },
    {
      x: 15,
      y: 19
    },
    {
      x: 14,
      y: 19
    },
    {
      x: 13,
      y: 19
    },
    {
      x: 12,
      y: 19
    },
    {
      x: 12,
      y: 18
    },
    {
      x: 13,
      y: 18
    },
    {
      x: 14,
      y: 18
    },
    {
      x: 11,
      y: 18
    },
    {
      x: 11,
      y: 17
    },
    {
      x: 12,
      y: 17
    },
    {
      x: 13,
      y: 13
    },
    {
      x: 12,
      y: 13
    },
    {
      x: 11,
      y: 12
    },
    {
      x: 11,
      y: 11
    },
    {
      x: 11,
      y: 10
    },
    {
      x: 12,
      y: 9
    },
    {
      x: 12,
      y: 10
    },
    {
      x: 12,
      y: 11
    },
    {
      x: 12,
      y: 12
    },
    {
      x: 13,
      y: 9
    },
    {
      x: 14,
      y: 9
    },
    {
      x: 15,
      y: 9
    },
    {
      x: 13,
      y: 10
    },
    {
      x: 14,
      y: 10
    },
    {
      x: 15,
      y: 10
    },
    {
      x: 16,
      y: 10
    },
    {
      x: 16,
      y: 11
    },
    {
      x: 15,
      y: 11
    },
    {
      x: 19,
      y: 10
    },
    {
      x: 20,
      y: 10
    },
    {
      x: 21,
      y: 10
    },
    {
      x: 22,
      y: 10
    },
    {
      x: 22,
      y: 9
    },
    {
      x: 21,
      y: 9
    },
    {
      x: 20,
      y: 9
    },
    {
      x: 19,
      y: 9
    },
    {
      x: 19,
      y: 19
    },
    {
      x: 20,
      y: 19
    },
    {
      x: 21,
      y: 19
    },
    {
      x: 22,
      y: 19
    },
    {
      x: 21,
      y: 18
    },
    {
      x: 22,
      y: 18
    },
    {
      x: 20,
      y: 18
    },
    {
      x: 19,
      y: 18
    },
    {
      x: 20,
      y: 11
    },
    {
      x: 21,
      y: 11
    },
    {
      x: 20,
      y: 12
    },
    {
      x: 21,
      y: 12
    },
    {
      x: 20,
      y: 13
    },
    {
      x: 21,
      y: 13
    },
    {
      x: 20,
      y: 14
    },
    {
      x: 21,
      y: 14
    },
    {
      x: 20,
      y: 15
    },
    {
      x: 21,
      y: 15
    },
    {
      x: 20,
      y: 16
    },
    {
      x: 21,
      y: 16
    },
    {
      x: 20,
      y: 17
    },
    {
      x: 21,
      y: 17
    },
    {
      x: 25,
      y: 10
    },
    {
      x: 25,
      y: 11
    },
    {
      x: 25,
      y: 12
    },
    {
      x: 25,
      y: 13
    },
    {
      x: 25,
      y: 14
    },
    {
      x: 25,
      y: 15
    },
    {
      x: 25,
      y: 16
    },
    {
      x: 25,
      y: 17
    },
    {
      x: 25,
      y: 18
    },
    {
      x: 26,
      y: 19
    },
    {
      x: 26,
      y: 18
    },
    {
      x: 26,
      y: 17
    },
    {
      x: 26,
      y: 16
    },
    {
      x: 26,
      y: 15
    },
    {
      x: 26,
      y: 14
    },
    {
      x: 26,
      y: 13
    },
    {
      x: 26,
      y: 12
    },
    {
      x: 26,
      y: 11
    },
    {
      x: 26,
      y: 10
    },
    {
      x: 26,
      y: 9
    },
    {
      x: 27,
      y: 9
    },
    {
      x: 27,
      y: 10
    },
    {
      x: 28,
      y: 10
    },
    {
      x: 28,
      y: 9
    },
    {
      x: 29,
      y: 9
    },
    {
      x: 29,
      y: 10
    },
    {
      x: 29,
      y: 11
    },
    {
      x: 30,
      y: 10
    },
    {
      x: 30,
      y: 11
    },
    {
      x: 27,
      y: 19
    },
    {
      x: 27,
      y: 18
    },
    {
      x: 28,
      y: 18
    },
    {
      x: 28,
      y: 19
    },
    {
      x: 29,
      y: 19
    },
    {
      x: 29,
      y: 18
    },
    {
      x: 30,
      y: 18
    },
    {
      x: 30,
      y: 17
    },
    {
      x: 30,
      y: 16
    },
    {
      x: 30,
      y: 15
    },
    {
      x: 29,
      y: 15
    },
    {
      x: 29,
      y: 16
    },
    {
      x: 29,
      y: 17
    },
    {
      x: 28,
      y: 16
    },
    {
      x: 28,
      y: 15
    },
    {
      x: 33,
      y: 9
    },
    {
      x: 33,
      y: 10
    },
    {
      x: 33,
      y: 11
    },
    {
      x: 33,
      y: 12
    },
    {
      x: 33,
      y: 13
    },
    {
      x: 33,
      y: 14
    },
    {
      x: 33,
      y: 15
    },
    {
      x: 33,
      y: 16
    },
    {
      x: 33,
      y: 17
    },
    {
      x: 33,
      y: 18
    },
    {
      x: 33,
      y: 19
    },
    {
      x: 34,
      y: 19
    },
    {
      x: 34,
      y: 18
    },
    {
      x: 34,
      y: 17
    },
    {
      x: 34,
      y: 16
    },
    {
      x: 34,
      y: 15
    },
    {
      x: 34,
      y: 14
    },
    {
      x: 34,
      y: 13
    },
    {
      x: 34,
      y: 12
    },
    {
      x: 34,
      y: 11
    },
    {
      x: 34,
      y: 10
    },
    {
      x: 34,
      y: 9
    },
    {
      x: 39,
      y: 9
    },
    {
      x: 39,
      y: 10
    },
    {
      x: 39,
      y: 11
    },
    {
      x: 39,
      y: 12
    },
    {
      x: 39,
      y: 13
    },
    {
      x: 39,
      y: 14
    },
    {
      x: 39,
      y: 15
    },
    {
      x: 39,
      y: 16
    },
    {
      x: 39,
      y: 17
    },
    {
      x: 39,
      y: 18
    },
    {
      x: 39,
      y: 19
    },
    {
      x: 40,
      y: 9
    },
    {
      x: 40,
      y: 10
    },
    {
      x: 40,
      y: 11
    },
    {
      x: 40,
      y: 12
    },
    {
      x: 40,
      y: 13
    },
    {
      x: 40,
      y: 14
    },
    {
      x: 40,
      y: 15
    },
    {
      x: 40,
      y: 16
    },
    {
      x: 40,
      y: 17
    },
    {
      x: 40,
      y: 18
    },
    {
      x: 40,
      y: 19
    },
    {
      x: 35,
      y: 12
    },
    {
      x: 35,
      y: 13
    },
    {
      x: 36,
      y: 13
    },
    {
      x: 36,
      y: 14
    },
    {
      x: 37,
      y: 14
    },
    {
      x: 37,
      y: 15
    },
    {
      x: 38,
      y: 15
    },
    {
      x: 38,
      y: 16
    },
    {
      x: 43,
      y: 10
    },
    {
      x: 43,
      y: 11
    },
    {
      x: 43,
      y: 12
    },
    {
      x: 43,
      y: 18
    },
    {
      x: 43,
      y: 17
    },
    {
      x: 44,
      y: 17
    },
    {
      x: 44,
      y: 18
    },
    {
      x: 44,
      y: 19
    },
    {
      x: 45,
      y: 18
    },
    {
      x: 45,
      y: 19
    },
    {
      x: 46,
      y: 18
    },
    {
      x: 46,
      y: 19
    },
    {
      x: 47,
      y: 18
    },
    {
      x: 47,
      y: 19
    },
    {
      x: 48,
      y: 18
    },
    {
      x: 48,
      y: 17
    },
    {
      x: 48,
      y: 16
    },
    {
      x: 47,
      y: 17
    },
    {
      x: 47,
      y: 16
    },
    {
      x: 47,
      y: 15
    },
    {
      x: 46,
      y: 15
    },
    {
      x: 46,
      y: 14
    },
    {
      x: 45,
      y: 14
    },
    {
      x: 45,
      y: 13
    },
    {
      x: 44,
      y: 13
    },
    {
      x: 44,
      y: 12
    },
    {
      x: 44,
      y: 11
    },
    {
      x: 44,
      y: 10
    },
    {
      x: 44,
      y: 9
    },
    {
      x: 45,
      y: 9
    },
    {
      x: 45,
      y: 10
    },
    {
      x: 46,
      y: 9
    },
    {
      x: 46,
      y: 10
    },
    {
      x: 47,
      y: 9
    },
    {
      x: 47,
      y: 10
    },
    {
      x: 47,
      y: 11
    },
    {
      x: 48,
      y: 10
    },
    {
      x: 48,
      y: 11
    }
  ]
};
