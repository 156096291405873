import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// components
import { Footer } from '../../components/footer/Footer';
import { Header } from '../../components/header/Header';

// props
import { MainTemplateProps } from './MainTemplateProps';

// styles
import * as styles from './MainTemplate.sass';

// template
export class MainTemplate extends Component<MainTemplateProps, {}> {
  render() {
    return (
      <div className={classNames(styles.main, this.props.className)}>
        <Header />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
