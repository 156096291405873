import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// components
import { About } from '../../components/about/About';
import { Affiliates } from '../../components/affiliates/Affiliates';
import { Component } from 'react';
import { Contact } from '../../components/contact/Contact';
import { Dispatch } from 'redux';
import { Features } from '../../components/features/Features';
import { Hero } from '../../components/hero/Hero';

// actions
import {
  selectMenuItemAction,
  ISelectMenuItemActionParams
} from '../../actions';

// interfaces
import {
  IHomePageOwnProps,
  IHomePageStateProps,
  IHomePageDispatchProps
} from './HomePage.i';

// styles
import * as styles from './HomePage.sass';

type Props = IHomePageOwnProps & IHomePageStateProps & IHomePageDispatchProps;

// component
class HomePageComponent extends Component<Props, {}> {
  componentWillMount() {
    this.props.selectMenuItem({
      menuItem: 'home'
    });
  }
  render() {
    return (
      <div className={classNames(styles.home, this.props.className)}>
        <Helmet>
          <title>Home</title>
          <meta
            name="description"
            content="Highway Barricades &amp; Services"
          />
          <meta
            name="keywords"
            content="highway,barricades,services,construction,equipment"
          />
        </Helmet>
        <Hero />
        <Features />
        <About />
        <Contact />
        <Affiliates />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectMenuItem: (params: ISelectMenuItemActionParams) => {
      dispatch(selectMenuItemAction(params));
    }
  };
}

export const HomePage = connect(
  null,
  mapDispatchToProps
)(HomePageComponent);
