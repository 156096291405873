import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Component } from 'react';
import { Helmet } from 'react-helmet';

// components
import { Container } from '../../components/container/Container';

// actions
import {
  selectMenuItemAction,
  ISelectMenuItemActionParams,
  ISetBreadcrumbsActionParams,
  setBreadcrumbsAction
} from '../../actions';

// interfaces
import {
  IErrorPageOwnProps,
  IErrorPageStateProps,
  IErrorPageDispatchProps
} from './ErrorPage.i';

// styles
import * as styles from './ErrorPage.sass';

// props
type Props = IErrorPageOwnProps &
  IErrorPageStateProps &
  IErrorPageDispatchProps;

// component
class ErrorPageComponent extends Component<Props, {}> {
  componentWillMount() {
    // this.props.selectMenuItem({
    //   menuItem: 'terms'
    // });
    this.props.setBreadcrumbs({
      breadcrumbsItems: [{ name: '404', url: '/*', text: '404' }]
    });
  }
  render() {
    return (
      <div className={classNames(styles.errorPage, this.props.className)}>
        <Helmet>
          <title>404</title>
          <meta
            name="description"
            content="404 - Highway Barricades &amp; Services"
          />
        </Helmet>
        <Container>
          <h1>404 - Page Not Found</h1>
        </Container>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectMenuItem: (params: ISelectMenuItemActionParams) => {
      dispatch(selectMenuItemAction(params));
    },
    setBreadcrumbs: (params: ISetBreadcrumbsActionParams) => {
      dispatch(setBreadcrumbsAction(params));
    }
  };
}

export const ErrorPage = connect(
  null,
  mapDispatchToProps
)(ErrorPageComponent);
