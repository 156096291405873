// actions
import { IListRecipientsAction } from '../../actions';

// constants
import { LIST_RECIPIENTS } from '../../constants';

import { IListRecipientsReducerState } from './listRecipientsReducer.i';

const initialState: IListRecipientsReducerState = {
  listRecipientsItems: []
};

export function listRecipientsReducer(
  state = initialState,
  action: IListRecipientsAction
): IListRecipientsReducerState {
  switch (action.type) {
    case LIST_RECIPIENTS: {
      return {
        ...state,
        listRecipientsItems: action.payload.recipients
      };
    }
    default: {
      return state;
    }
  }
}
