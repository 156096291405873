import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Component } from 'react';
import { Helmet } from 'react-helmet';

// actions
import {
  selectMenuItemAction,
  ISelectMenuItemActionParams,
  ISetBreadcrumbsActionParams,
  setBreadcrumbsAction
} from '../../actions';

// components
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Container } from '../../components/container/Container';
import { ContactUsForm } from '../../components/contactUsForm/ContactUsForm';
import { ContactSummary } from '../../components/contactSummary/ContactSummary';

// styles
import * as styles from './ContactPage.sass';

// component
class ContactPageComponent extends Component<
  IContactPageProps,
  IContactPageState
> {
  componentWillMount() {
    this.props.selectMenuItem({
      menuItem: 'contact'
    });
    this.props.setBreadcrumbs({
      breadcrumbsItems: [{ name: 'contact', url: 'contact', text: 'Contact' }]
    });
  }
  render() {
    return (
      <div className={classNames(styles.contact, this.props.className)}>
        <Helmet>
          <title>Contact</title>
          <meta
            name="description"
            content="Contact Highway Barricades &amp; Services"
          />
          <meta
            name="keywords"
            content="contact,highway,barricades,services,traffic,control,company,quote,questions"
          />
        </Helmet>
        <Breadcrumbs />
        <ContactSummary />
        <Container className={styles.contentContainer}>
          <ContactUsForm />
          <div className={styles.imgContainer}>
            <div className={styles.slider}>
              <div className={styles.sliderContent}>
                <div className={styles.sliderContentItem}>
                  <div className={styles.sliderTitle}>Address</div>
                  <div className={styles.orangeBar} />
                  <div className={styles.sliderDescription}>
                    Highway Barricades &amp; Services, LLC
                    <br />
                    7775 Leopard St.
                    <br />
                    Corpus Christi, TX 78409
                  </div>
                </div>
                <div className={styles.sliderBottomContent}>
                  <div className={styles.sliderContentItem}>
                    <div className={styles.sliderTitle}>Phone</div>
                    <div className={styles.orangeBar} />
                    <div className={styles.sliderDescription}>
                      <a className={styles.phoneLink} href="tel:361-883-6300">
                        361-883-6300
                      </a>
                    </div>
                  </div>
                  <div className={styles.sliderContentItem}>
                    <div className={styles.sliderTitle}>Fax</div>
                    <div className={styles.orangeBar} />
                    <div className={styles.sliderDescription}>361-883-6301</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectMenuItem: (params: ISelectMenuItemActionParams) => {
      dispatch(selectMenuItemAction(params));
    },
    setBreadcrumbs: (params: ISetBreadcrumbsActionParams) => {
      dispatch(setBreadcrumbsAction(params));
    }
  };
}

export const ContactPage = connect(
  null,
  mapDispatchToProps
)(ContactPageComponent);
