exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'Helvetica Neue LT Std';\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"assets/HelveticaNeueLTStd-Cn.otf\"); }\n\np {\n  font-family: 'Roboto'; }\n\n.TermsOfUse__main__2DBIz {\n  font-family: Verdana; }\n  @media screen and (max-width: 1000px) {\n    .TermsOfUse__main__2DBIz {\n      padding: 0 30px; } }\n\n.TermsOfUse__termsTitle__2jrVN {\n  color: black;\n  font-family: 'Roboto';\n  text-transform: uppercase;\n  font-size: 1.5em;\n  line-height: 20px; }\n  @media screen and (max-width: 1000px) {\n    .TermsOfUse__termsTitle__2jrVN {\n      margin-top: 30px; } }\n\n.TermsOfUse__orangeBar__4clKh {\n  background: #f18621;\n  width: 50px;\n  height: 2px;\n  margin: 23px 0 10px 0; }\n  @media screen and (max-width: 1000px) {\n    .TermsOfUse__orangeBar__4clKh {\n      text-align: center;\n      display: inline-block;\n      margin: 23px 0 5px 0; } }\n", ""]);

// exports
exports.locals = {
	"main": "TermsOfUse__main__2DBIz",
	"termsTitle": "TermsOfUse__termsTitle__2jrVN",
	"orangeBar": "TermsOfUse__orangeBar__4clKh"
};