exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Contact__main__3oMFh {\n  font-family: Verdana; }\n\n.Contact__contactContainer__1R5l3 {\n  padding: 90px 10px; }\n  @media screen and (max-width: 1000px) {\n    .Contact__contactContainer__1R5l3 {\n      display: grid;\n      grid-template-columns: 1fr;\n      justify-items: center;\n      text-align: center; } }\n\n.Contact__orangeBar__nkiQj {\n  background: #f18621;\n  width: 50px;\n  height: 2px;\n  margin: 23px 0 23px 0; }\n\n.Contact__title__3QfT3 {\n  color: black;\n  font-family: 'Roboto';\n  text-transform: uppercase;\n  font-size: 1.5em;\n  line-height: 20px; }\n\n.Contact__formImageContainer__3tvJQ {\n  display: grid;\n  grid-template-columns: 50% 50%;\n  grid-column-gap: 30px; }\n  @media screen and (max-width: 1000px) {\n    .Contact__formImageContainer__3tvJQ {\n      display: grid;\n      grid-template-columns: 1fr;\n      justify-items: center;\n      width: 100%;\n      min-width: 400px;\n      padding: 0 0;\n      margin: 0 0; } }\n  @media screen and (max-width: 600px) {\n    .Contact__formImageContainer__3tvJQ {\n      min-width: 200px; } }\n\n.Contact__barricadeImg__WUkkm {\n  max-height: 400px; }\n\n.Contact__imgContainer__2XAJi {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 580px;\n  width: 100%; }\n", ""]);

// exports
exports.locals = {
	"main": "Contact__main__3oMFh",
	"contactContainer": "Contact__contactContainer__1R5l3",
	"orangeBar": "Contact__orangeBar__nkiQj",
	"title": "Contact__title__3QfT3",
	"formImageContainer": "Contact__formImageContainer__3tvJQ",
	"barricadeImg": "Contact__barricadeImg__WUkkm",
	"imgContainer": "Contact__imgContainer__2XAJi"
};