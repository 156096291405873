import * as classNames from 'classnames';
import * as React from 'react';

// states
import { useContactForm, useRecipients } from '../../states';

// styles
import * as styles from './ContactUsForm.sass';

export function ContactUsForm({ className = '' }) {
  // recipients state
  const recipients = useRecipients();

  // contact form state
  const contactForm = useContactForm();

  // recipients are loading
  if (recipients.isLoading) {
    return <div>Loading Contact Form...</div>;
  }

  // error getting recipients
  if (recipients.errorMessage) {
    return <div>{recipients.errorMessage}</div>;
  }

  // contact form is complete
  if (contactForm.isComplete) {
    return <div>Thanks for writing.</div>;
  }

  return (
    <div className={classNames(styles.main, className)}>
      {contactForm.errorMessage && <div className={styles.error}>{contactForm.errorMessage}</div>}
      <form onSubmit={() => contactForm.handleSubmit(event)}>
        <div className={styles.container}>
          <div>
            <label htmlFor="recipientId">Message Recipient</label>
            <select
              disabled={contactForm.isSubmitting}
              name="recipientId"
              onChange={event => contactForm.setRecipientId(event.target.value)}
              value={contactForm.recipientId}
            >
              {recipients.data.map(recipient => (
                <option key={recipient.id} value={recipient.id}>
                  {recipient.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="name">Your Name</label>
            <input
              disabled={contactForm.isSubmitting}
              name="name"
              onChange={event => contactForm.setName(event.target.value)}
              value={contactForm.name}
            />
          </div>
          <div>
            <label htmlFor="email">Your Email</label>
            <input
              disabled={contactForm.isSubmitting}
              name="email"
              onChange={event => contactForm.setEmail(event.target.value)}
              value={contactForm.email}
            />
          </div>
          <div>
            <label htmlFor="phone">Your Phone Number</label>
            <input
              disabled={contactForm.isSubmitting}
              name="phone"
              onChange={event => contactForm.setPhone(event.target.value)}
              value={contactForm.phone}
            />
          </div>
          <div>
            <label htmlFor="message">Your Message</label>
            <textarea
              disabled={contactForm.isSubmitting}
              name="message"
              onChange={event => contactForm.setMessage(event.target.value)}
              value={contactForm.message}
            />
          </div>
        </div>
        <div>
          <button className={styles.submitButton} type="submit">
            {contactForm.isSubmitting ? 'Loading...' : 'Send Message'}
          </button>
        </div>
      </form>
    </div>
  );
}
