import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import { Fade } from 'react-reveal';

// components
import { Container } from '../../components/container/Container';
import { ContactUsForm } from '../../components/contactUsForm/ContactUsForm';

// props
// import { ContactProps } from './ContactProps';

// styles
import * as styles from './Contact.sass';

// template
export class Contact extends Component<IContactProps, IContactState, {}> {
  render() {
    return (
      <div className={classNames(styles.main, this.props.className)}>
        <Container className={styles.contactContainer}>
          <Fade left cascade>
            <div className={styles.title}>Contact Us</div>
            <div className={styles.orangeBar} />
          </Fade>
          <div className={styles.formImageContainer}>
            <Fade left>
              <ContactUsForm />
            </Fade>
            <Fade right>
              <div className={styles.imgContainer}>
                <img
                  className={styles.barricadeImg}
                  src="assets/quote-barricade.png"
                />
              </div>
            </Fade>
          </div>
        </Container>
      </div>
    );
  }
}
