import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// components
import { Container } from '../container/Container';

// props
import { FooterProps } from './FooterProps';

// styles
import * as styles from './Footer.sass';

// component
export class Footer extends Component<FooterProps, {}> {
  render() {
    return (
      <header className={classNames(styles.footer, this.props.className)}>
        <Container>
          <div className={styles.footerContainer}>
            <div className={styles.leftFooterContainer}>
              <div className={styles.footerContentTitle}>
                HIGHWAY BARRICADES &amp; SERVICES
              </div>
              <div className={styles.orangeBar} />
              <p className={styles.footerContentDescription}>
              Highway Barricades and Services is a H.U.B./D.B.E. certified Corpus Christi based, full service traffic control company that prides itself on being flexible for its customers. We offer traffic control planning, setup, maintenance and take down of traffic devices, as well as both sales and rental of our equipment inventory. In addition to traffic control, we provide permanent signs, trench safety and pavement marking services.
              </p>
            </div>
            <div className={styles.centerFooterContainer}>
              <div className={styles.footerContentTitle}>NAVIGATION</div>
              <div className={styles.orangeBar} />
              <div className={styles.footerContentLinksDiv}>
                <div className={styles.footerContentLink}>
                  <a className={styles.footerContentLink} href="/">
                    Home
                  </a>
                </div>
                <div className={styles.footerContentLink}>
                  <a className={styles.footerContentLink} href="about">
                    About
                  </a>
                </div>
                <div className={styles.footerContentLink}>
                  <a className={styles.footerContentLink} href="services">
                    Services
                  </a>
                </div>
                <div className={styles.footerContentLink}>
                  <a className={styles.footerContentLink} href="equipment">
                    Equipment
                  </a>
                </div>
                <div className={styles.footerContentLink}>
                  <a className={styles.footerContentLink} href="events">
                    Special Events
                  </a>
                </div>
              </div>
              <a className={styles.footerContentButton} href="">
                Contact Us
              </a>
            </div>
            <div className={styles.rightFooterContainer}>
              <div>
                <div className={styles.footerContentTitle}>CONTACT</div>
                <div className={styles.orangeBar} />
                <div className={styles.footerContentDescription}>
                  <a className={styles.contactLinks} href="tel:361-883-6300">
                    <img
                      className={styles.contactIcon}
                      src="assets/phone-symbol-of-an-auricular-inside-a-circle.svg"
                    />
                    <div>(361) 883-6300</div>
                  </a>
                  <div className={styles.contactLinks}>
                    <img
                      className={styles.contactIcon}
                      src="assets/printer-.svg"
                    />
                    <div>(361) 883-6301</div>
                  </div>

                  <a
                    className={styles.contactLinks}
                    href="mailto:info@hbstexas.com"
                  >
                    <img
                      className={styles.contactIcon}
                      src="assets/envelope.svg"
                    />
                    <div>info@hbstexas.com</div>
                  </a>
                </div>
              </div>
              <div className={styles.addressDiv}>
                <div className={styles.footerContentTitle}>ADDRESS</div>
                <div className={styles.orangeBar} />
                <p className={styles.footerContentDescription}>
                  7775 Leopard St.
                  <br />
                  Corpus Christi, TX 78409
                </p>
              </div>
            </div>
          </div>
        </Container>
        <div className={styles.bottomFooterBackground}>
          <Container className={styles.bottomFooter}>
            <div className={styles.bottomFooterLeft}>
              <a href="terms">Terms of Use</a>
              &nbsp;&nbsp;&nbsp;
              <a href="privacy">Privacy Policy</a>
            </div>
            <div className={styles.bottomFooterRight}>
              <p>&copy; 2018 Highway Baricades and Services, LLC.</p>
            </div>
          </Container>
        </div>
      </header>
    );
  }
}
