exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Container__container__ZEm5z {\n  margin: 0 auto;\n  max-width: 1000px; }\n", ""]);

// exports
exports.locals = {
	"container": "Container__container__ZEm5z"
};