exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'Helvetica Neue LT Std';\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"assets/HelveticaNeueLTStd-Cn.otf\"); }\n\n.Mission__mission__2-kcf {\n  font-family: Verdana; }\n\n.Mission__missionContentContainer__-CMTn {\n  display: flex;\n  justify-content: center; }\n  @media screen and (max-width: 1000px) {\n    .Mission__missionContentContainer__-CMTn {\n      display: grid;\n      grid-template-columns: 1fr;\n      justify-items: center;\n      text-align: center; } }\n\n.Mission__missionDescription__2eBFn {\n  color: #999;\n  font-family: 'Roboto';\n  font-size: 1em;\n  line-height: 26px; }\n\n.Mission__missionImage__2k2Tg {\n  background: url(\"assets/our-promise.jpg\");\n  height: 450px;\n  width: 450px;\n  margin-right: 40px;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat; }\n  @media screen and (max-width: 1000px) {\n    .Mission__missionImage__2k2Tg {\n      text-align: center;\n      margin-right: 0; } }\n  @media screen and (max-width: 576px) {\n    .Mission__missionImage__2k2Tg {\n      height: 250px;\n      width: 250px; } }\n\n.Mission__missionText__Pc750 {\n  width: 50%;\n  padding: 20px 0; }\n  @media screen and (max-width: 768px) {\n    .Mission__missionText__Pc750 {\n      width: 70%; } }\n  @media screen and (max-width: 576px) {\n    .Mission__missionText__Pc750 {\n      width: 90%; } }\n\n.Mission__missionTitle__2TKJ4 {\n  color: black;\n  font-family: 'Roboto';\n  text-transform: uppercase;\n  font-size: 1.5em;\n  line-height: 20px; }\n  @media screen and (max-width: 1000px) {\n    .Mission__missionTitle__2TKJ4 {\n      margin-top: 30px; } }\n\n.Mission__orangeBar__2XjlV {\n  background: #f18621;\n  width: 50px;\n  height: 2px;\n  margin: 23px 0 10px 0; }\n  @media screen and (max-width: 1000px) {\n    .Mission__orangeBar__2XjlV {\n      text-align: center;\n      display: inline-block;\n      margin: 23px 0 5px 0; } }\n", ""]);

// exports
exports.locals = {
	"mission": "Mission__mission__2-kcf",
	"missionContentContainer": "Mission__missionContentContainer__-CMTn",
	"missionDescription": "Mission__missionDescription__2eBFn",
	"missionImage": "Mission__missionImage__2k2Tg",
	"missionText": "Mission__missionText__Pc750",
	"missionTitle": "Mission__missionTitle__2TKJ4",
	"orangeBar": "Mission__orangeBar__2XjlV"
};