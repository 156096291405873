exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HomePage__home__2_C_F {\n  padding: 0 0 0 0; }\n", ""]);

// exports
exports.locals = {
	"home": "HomePage__home__2_C_F"
};