exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'Helvetica Neue LT Std';\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"assets/HelveticaNeueLTStd-Cn.otf\"); }\n\n.Explore__exploreDescription__3QxBQ {\n  font-family: 'Roboto';\n  font-size: 1em;\n  line-height: 26px; }\n\n.Explore__exploreImage__3Cbwz {\n  background: url(\"./assets/special-events.jpg\");\n  height: 400px;\n  width: 100%;\n  margin: 25px 0;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat; }\n  @media screen and (max-width: 1000px) {\n    .Explore__exploreImage__3Cbwz {\n      text-align: center;\n      margin-right: 0; } }\n  @media screen and (max-width: 576px) {\n    .Explore__exploreImage__3Cbwz {\n      height: 250px;\n      width: 250px; } }\n\n.Explore__exploreText__1bKHU {\n  padding: 20px 0; }\n  @media screen and (max-width: 1000px) {\n    .Explore__exploreText__1bKHU {\n      padding: 20px 20px; } }\n  @media screen and (max-width: 576px) {\n    .Explore__exploreText__1bKHU {\n      display: grid;\n      grid-template-columns: 1fr;\n      justify-items: center; } }\n\n.Explore__exploreTitle__3TjAH {\n  color: black;\n  font-family: 'Roboto';\n  text-transform: uppercase;\n  font-size: 1.5em;\n  line-height: 20px; }\n  @media screen and (max-width: 1000px) {\n    .Explore__exploreTitle__3TjAH {\n      margin-top: 30px;\n      padding: 0 20px; } }\n\n.Explore__main__2WbXA {\n  font-family: 'Roboto'; }\n\n.Explore__list__2zfUj {\n  margin-left: 100px;\n  color: black; }\n  @media screen and (max-width: 576px) {\n    .Explore__list__2zfUj {\n      margin-left: 50px; } }\n\n.Explore__listItem__1Oa42 {\n  padding: 5px 5px;\n  color: black; }\n\n.Explore__orangeBar__j4qo_ {\n  background: #f18621;\n  width: 50px;\n  height: 2px;\n  margin: 23px 0 10px 0; }\n  @media screen and (max-width: 1000px) {\n    .Explore__orangeBar__j4qo_ {\n      text-align: center;\n      display: inline-block;\n      margin: 23px 20px 5px 20px; } }\n", ""]);

// exports
exports.locals = {
	"exploreDescription": "Explore__exploreDescription__3QxBQ",
	"exploreImage": "Explore__exploreImage__3Cbwz",
	"exploreText": "Explore__exploreText__1bKHU",
	"exploreTitle": "Explore__exploreTitle__3TjAH",
	"main": "Explore__main__2WbXA",
	"list": "Explore__list__2zfUj",
	"listItem": "Explore__listItem__1Oa42",
	"orangeBar": "Explore__orangeBar__j4qo_"
};