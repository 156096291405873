exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ServicesPage__services__3AISf {\n  padding: 0 0 100px 0;\n  background: #f5f5f5; }\n  @media screen and (max-width: 1000px) {\n    .ServicesPage__services__3AISf {\n      padding: 0 0; } }\n", ""]);

// exports
exports.locals = {
	"services": "ServicesPage__services__3AISf"
};