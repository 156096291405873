import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// actions
import {
  selectMenuItemAction,
  ISelectMenuItemActionParams,
  ISetBreadcrumbsActionParams,
  setBreadcrumbsAction
} from '../../actions';

// components
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Explore } from '../../components/explore/Explore';

// interfaces
import { IAppState } from '../../App.i';

// styles
import * as styles from './ExplorePage.sass';

// component
class ExplorePageComponent extends Component<
  IExplorePageProps,
  IExplorePageState,
  {}
> {
  state = {
    isUnknown: false,
    name: '',
    title: '',
    type: ''
  }
  componentWillMount() {
    const { name, type } = this.props.match.params;

    const typeUrl = `/${type}`;
    const typeText = type.charAt(0).toUpperCase() + type.slice(1);

    const items = type === 'services'
      ? this.props.services
      : this.props.equipment;
    const item = items.find((item: any) => item.url === name);

    if (!item) {
      this.setState({
        isUnknown: true
      });
      return;
    }

    this.props.selectMenuItem({
      menuItem: this.props.match.params.type
    });
    this.props.setBreadcrumbs({
      breadcrumbsItems: [
        { name: type, url: typeUrl, text: typeText },
        { name: item.url, url: `${typeUrl}/${item.url}`, text: item.name }
      ]
    });

    this.setState({
      name,
      title: `${item.name} | ${typeText}`,
      type
    });
  }
  render() {
    if (this.state.isUnknown) {
      return (
        <Redirect
            to={{
              pathname: '/'
            }}
          />
      );
    }
    return (
      <div className={classNames(styles.explore, this.props.className)}>
        <Helmet>
          <title>{this.state.title}</title>
          <meta
            name="description"
            content="Explore - Highway Barricades &amp; Services"
          />
          <meta
            name="keywords"
            content="highway,barricades,services,explore,traffic,control,equipment,fencing,planning"
          />
        </Helmet>
        <Breadcrumbs />
        <Explore name={this.state.name} type={this.state.type} />
      </div>
    );
  }
}

function mapStateToProps(state: IAppState): IExplorePageStateProps {
  return {
    equipment: state.equipment.equipment,
    services: state.services.services
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectMenuItem: (params: ISelectMenuItemActionParams) => {
      dispatch(selectMenuItemAction(params));
    },
    setBreadcrumbs: (params: ISetBreadcrumbsActionParams) => {
      dispatch(setBreadcrumbsAction(params));
    }
  };
}

export const ExplorePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExplorePageComponent);
