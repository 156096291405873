import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// components
import { Container } from '../container/Container';
import { Logo } from '../logo/Logo';
import { Menu } from '../menu/Menu';
import { ResponsiveMenu } from '../responsiveMenu/ResponsiveMenu';

// interfaces
import {
  IHeaderOwnProps,
  IHeaderStateProps,
  IHeaderDispatchProps
} from './Header.i';

// styles
import * as styles from './Header.sass';

// props
type Props = IHeaderOwnProps & IHeaderStateProps & IHeaderDispatchProps;

// component
export class Header extends Component<Props, {}> {
  render() {
    return (
      <header className={classNames(styles.header, this.props.className)}>
        <div className={styles.headerContact}>
          <Container className={styles.headerContactContent}>
            <div className={styles.headerContactItemsDiv}>
              <div className={styles.headerContactItem}>
                <img
                  className={styles.headerIcon}
                  src="assets/phone-symbol-of-an-auricular-inside-a-circle.svg"
                />{' '}
                <a href="tel:361-883-6300">(361) 883-6300</a>
              </div>
              <div className={styles.headerContactItem}>|</div>
              <div
                className={classNames(styles.headerContactItem, styles.faxIcon)}
              >
                <img className={styles.headerIcon} src="assets/printer-.svg" />{' '}
                (361) 883-6301
              </div>
              <div
                className={classNames(styles.headerContactItem, styles.faxIcon)}
              >
                |
              </div>
              <div className={styles.headerContactItem}>
                <img className={styles.headerIcon} src="assets/envelope.svg" />{' '}
                <a href="mailto:info@hbstexas.com">info@hbstexas.com</a>
              </div>
            </div>
          </Container>
        </div>
        <Container className={styles.container}>
          <Logo className={styles.logo} />
          <Menu className={styles.menu} />
          <button
            onClick={() => {
              const menuButton = document.getElementById('menuLinks');
              const menuBackground = document.getElementById('menuBackground');
              menuButton.style.width == '200px'
                ? menuButton.setAttribute('style', 'width: 0')
                : menuButton.setAttribute('style', 'width: 200px');
              menuBackground.style.width == '100vw'
                ? menuBackground.setAttribute('style', 'width: 0')
                : menuBackground.setAttribute('style', 'width: 100vw');
            }}
            className={styles.menuIconButton}
          />
          <div className={styles.getAquoteContainer}>
            <a href="contact" className={styles.getAquote}>
              Contact Us
            </a>
          </div>
        </Container>
        <ResponsiveMenu />
      </header>
    );
  }
}
