export const TrafficDevices = {
  xOffset: 0,
  yOffset: 2,
  pixels: [
    {
      "x": 4,
      "y": 4
    },
    {
      "x": 5,
      "y": 4
    },
    {
      "x": 6,
      "y": 4
    },
    {
      "x": 7,
      "y": 4
    },
    {
      "x": 8,
      "y": 4
    },
    {
      "x": 9,
      "y": 4
    },
    {
      "x": 6,
      "y": 5
    },
    {
      "x": 6,
      "y": 6
    },
    {
      "x": 6,
      "y": 7
    },
    {
      "x": 6,
      "y": 8
    },
    {
      "x": 7,
      "y": 5
    },
    {
      "x": 7,
      "y": 6
    },
    {
      "x": 7,
      "y": 7
    },
    {
      "x": 7,
      "y": 8
    },
    {
      "x": 6,
      "y": 9
    },
    {
      "x": 7,
      "y": 9
    },
    {
      "x": 6,
      "y": 10
    },
    {
      "x": 7,
      "y": 10
    },
    {
      "x": 12,
      "y": 4
    },
    {
      "x": 13,
      "y": 4
    },
    {
      "x": 14,
      "y": 4
    },
    {
      "x": 15,
      "y": 4
    },
    {
      "x": 16,
      "y": 4
    },
    {
      "x": 12,
      "y": 5
    },
    {
      "x": 12,
      "y": 6
    },
    {
      "x": 12,
      "y": 7
    },
    {
      "x": 12,
      "y": 8
    },
    {
      "x": 12,
      "y": 9
    },
    {
      "x": 12,
      "y": 10
    },
    {
      "x": 13,
      "y": 10
    },
    {
      "x": 13,
      "y": 9
    },
    {
      "x": 13,
      "y": 8
    },
    {
      "x": 13,
      "y": 7
    },
    {
      "x": 13,
      "y": 6
    },
    {
      "x": 13,
      "y": 5
    },
    {
      "x": 16,
      "y": 5
    },
    {
      "x": 17,
      "y": 5
    },
    {
      "x": 17,
      "y": 6
    },
    {
      "x": 16,
      "y": 6
    },
    {
      "x": 15,
      "y": 7
    },
    {
      "x": 14,
      "y": 7
    },
    {
      "x": 15,
      "y": 8
    },
    {
      "x": 16,
      "y": 8
    },
    {
      "x": 16,
      "y": 9
    },
    {
      "x": 17,
      "y": 9
    },
    {
      "x": 17,
      "y": 10
    },
    {
      "x": 20,
      "y": 10
    },
    {
      "x": 20,
      "y": 9
    },
    {
      "x": 20,
      "y": 8
    },
    {
      "x": 20,
      "y": 7
    },
    {
      "x": 20,
      "y": 6
    },
    {
      "x": 21,
      "y": 6
    },
    {
      "x": 21,
      "y": 7
    },
    {
      "x": 21,
      "y": 8
    },
    {
      "x": 21,
      "y": 9
    },
    {
      "x": 21,
      "y": 10
    },
    {
      "x": 22,
      "y": 8
    },
    {
      "x": 23,
      "y": 8
    },
    {
      "x": 24,
      "y": 6
    },
    {
      "x": 25,
      "y": 6
    },
    {
      "x": 25,
      "y": 7
    },
    {
      "x": 24,
      "y": 7
    },
    {
      "x": 24,
      "y": 8
    },
    {
      "x": 24,
      "y": 9
    },
    {
      "x": 24,
      "y": 10
    },
    {
      "x": 25,
      "y": 10
    },
    {
      "x": 25,
      "y": 9
    },
    {
      "x": 25,
      "y": 8
    },
    {
      "x": 21,
      "y": 5
    },
    {
      "x": 22,
      "y": 4
    },
    {
      "x": 23,
      "y": 4
    },
    {
      "x": 24,
      "y": 5
    },
    {
      "x": 28,
      "y": 10
    },
    {
      "x": 29,
      "y": 10
    },
    {
      "x": 29,
      "y": 9
    },
    {
      "x": 28,
      "y": 9
    },
    {
      "x": 28,
      "y": 8
    },
    {
      "x": 29,
      "y": 8
    },
    {
      "x": 29,
      "y": 7
    },
    {
      "x": 28,
      "y": 7
    },
    {
      "x": 28,
      "y": 6
    },
    {
      "x": 29,
      "y": 6
    },
    {
      "x": 28,
      "y": 5
    },
    {
      "x": 29,
      "y": 5
    },
    {
      "x": 28,
      "y": 4
    },
    {
      "x": 29,
      "y": 4
    },
    {
      "x": 30,
      "y": 4
    },
    {
      "x": 31,
      "y": 4
    },
    {
      "x": 32,
      "y": 4
    },
    {
      "x": 33,
      "y": 4
    },
    {
      "x": 30,
      "y": 7
    },
    {
      "x": 31,
      "y": 7
    },
    {
      "x": 32,
      "y": 7
    },
    {
      "x": 36,
      "y": 4
    },
    {
      "x": 36,
      "y": 5
    },
    {
      "x": 36,
      "y": 6
    },
    {
      "x": 36,
      "y": 7
    },
    {
      "x": 36,
      "y": 8
    },
    {
      "x": 36,
      "y": 9
    },
    {
      "x": 36,
      "y": 10
    },
    {
      "x": 37,
      "y": 10
    },
    {
      "x": 37,
      "y": 9
    },
    {
      "x": 37,
      "y": 8
    },
    {
      "x": 37,
      "y": 7
    },
    {
      "x": 37,
      "y": 6
    },
    {
      "x": 37,
      "y": 5
    },
    {
      "x": 37,
      "y": 4
    },
    {
      "x": 38,
      "y": 4
    },
    {
      "x": 39,
      "y": 4
    },
    {
      "x": 40,
      "y": 4
    },
    {
      "x": 41,
      "y": 4
    },
    {
      "x": 38,
      "y": 7
    },
    {
      "x": 39,
      "y": 7
    },
    {
      "x": 40,
      "y": 7
    },
    {
      "x": 44,
      "y": 4
    },
    {
      "x": 45,
      "y": 4
    },
    {
      "x": 46,
      "y": 4
    },
    {
      "x": 47,
      "y": 4
    },
    {
      "x": 45,
      "y": 5
    },
    {
      "x": 45,
      "y": 6
    },
    {
      "x": 45,
      "y": 7
    },
    {
      "x": 45,
      "y": 8
    },
    {
      "x": 45,
      "y": 9
    },
    {
      "x": 45,
      "y": 10
    },
    {
      "x": 44,
      "y": 10
    },
    {
      "x": 46,
      "y": 10
    },
    {
      "x": 47,
      "y": 10
    },
    {
      "x": 46,
      "y": 9
    },
    {
      "x": 46,
      "y": 8
    },
    {
      "x": 46,
      "y": 7
    },
    {
      "x": 46,
      "y": 6
    },
    {
      "x": 46,
      "y": 5
    },
    {
      "x": 50,
      "y": 5
    },
    {
      "x": 50,
      "y": 6
    },
    {
      "x": 50,
      "y": 7
    },
    {
      "x": 50,
      "y": 8
    },
    {
      "x": 50,
      "y": 9
    },
    {
      "x": 51,
      "y": 10
    },
    {
      "x": 51,
      "y": 9
    },
    {
      "x": 51,
      "y": 8
    },
    {
      "x": 51,
      "y": 7
    },
    {
      "x": 51,
      "y": 6
    },
    {
      "x": 51,
      "y": 5
    },
    {
      "x": 51,
      "y": 4
    },
    {
      "x": 52,
      "y": 4
    },
    {
      "x": 53,
      "y": 4
    },
    {
      "x": 54,
      "y": 4
    },
    {
      "x": 54,
      "y": 5
    },
    {
      "x": 55,
      "y": 5
    },
    {
      "x": 52,
      "y": 10
    },
    {
      "x": 53,
      "y": 10
    },
    {
      "x": 54,
      "y": 10
    },
    {
      "x": 54,
      "y": 9
    },
    {
      "x": 55,
      "y": 9
    },
    {
      "x": 4,
      "y": 15
    },
    {
      "x": 4,
      "y": 16
    },
    {
      "x": 4,
      "y": 17
    },
    {
      "x": 4,
      "y": 18
    },
    {
      "x": 4,
      "y": 19
    },
    {
      "x": 4,
      "y": 20
    },
    {
      "x": 4,
      "y": 21
    },
    {
      "x": 5,
      "y": 21
    },
    {
      "x": 5,
      "y": 20
    },
    {
      "x": 5,
      "y": 18
    },
    {
      "x": 5,
      "y": 19
    },
    {
      "x": 5,
      "y": 17
    },
    {
      "x": 5,
      "y": 16
    },
    {
      "x": 5,
      "y": 15
    },
    {
      "x": 6,
      "y": 15
    },
    {
      "x": 7,
      "y": 15
    },
    {
      "x": 8,
      "y": 15
    },
    {
      "x": 8,
      "y": 16
    },
    {
      "x": 8,
      "y": 17
    },
    {
      "x": 8,
      "y": 18
    },
    {
      "x": 8,
      "y": 19
    },
    {
      "x": 8,
      "y": 20
    },
    {
      "x": 8,
      "y": 21
    },
    {
      "x": 7,
      "y": 21
    },
    {
      "x": 6,
      "y": 21
    },
    {
      "x": 9,
      "y": 20
    },
    {
      "x": 9,
      "y": 19
    },
    {
      "x": 9,
      "y": 18
    },
    {
      "x": 9,
      "y": 17
    },
    {
      "x": 9,
      "y": 16
    },
    {
      "x": 12,
      "y": 15
    },
    {
      "x": 12,
      "y": 16
    },
    {
      "x": 12,
      "y": 17
    },
    {
      "x": 12,
      "y": 18
    },
    {
      "x": 12,
      "y": 19
    },
    {
      "x": 12,
      "y": 20
    },
    {
      "x": 12,
      "y": 21
    },
    {
      "x": 13,
      "y": 15
    },
    {
      "x": 13,
      "y": 16
    },
    {
      "x": 13,
      "y": 17
    },
    {
      "x": 13,
      "y": 18
    },
    {
      "x": 13,
      "y": 19
    },
    {
      "x": 13,
      "y": 20
    },
    {
      "x": 13,
      "y": 21
    },
    {
      "x": 14,
      "y": 15
    },
    {
      "x": 15,
      "y": 15
    },
    {
      "x": 16,
      "y": 15
    },
    {
      "x": 17,
      "y": 15
    },
    {
      "x": 14,
      "y": 18
    },
    {
      "x": 15,
      "y": 18
    },
    {
      "x": 16,
      "y": 18
    },
    {
      "x": 14,
      "y": 21
    },
    {
      "x": 15,
      "y": 21
    },
    {
      "x": 16,
      "y": 21
    },
    {
      "x": 17,
      "y": 21
    },
    {
      "x": 20,
      "y": 15
    },
    {
      "x": 20,
      "y": 16
    },
    {
      "x": 20,
      "y": 17
    },
    {
      "x": 21,
      "y": 15
    },
    {
      "x": 21,
      "y": 16
    },
    {
      "x": 21,
      "y": 17
    },
    {
      "x": 21,
      "y": 18
    },
    {
      "x": 21,
      "y": 19
    },
    {
      "x": 22,
      "y": 20
    },
    {
      "x": 22,
      "y": 21
    },
    {
      "x": 23,
      "y": 21
    },
    {
      "x": 23,
      "y": 20
    },
    {
      "x": 24,
      "y": 19
    },
    {
      "x": 24,
      "y": 18
    },
    {
      "x": 24,
      "y": 17
    },
    {
      "x": 24,
      "y": 16
    },
    {
      "x": 24,
      "y": 15
    },
    {
      "x": 25,
      "y": 15
    },
    {
      "x": 25,
      "y": 16
    },
    {
      "x": 25,
      "y": 17
    },
    {
      "x": 28,
      "y": 15
    },
    {
      "x": 29,
      "y": 15
    },
    {
      "x": 30,
      "y": 15
    },
    {
      "x": 31,
      "y": 15
    },
    {
      "x": 29,
      "y": 16
    },
    {
      "x": 30,
      "y": 16
    },
    {
      "x": 29,
      "y": 17
    },
    {
      "x": 30,
      "y": 17
    },
    {
      "x": 29,
      "y": 18
    },
    {
      "x": 30,
      "y": 18
    },
    {
      "x": 29,
      "y": 19
    },
    {
      "x": 30,
      "y": 19
    },
    {
      "x": 29,
      "y": 20
    },
    {
      "x": 30,
      "y": 20
    },
    {
      "x": 30,
      "y": 21
    },
    {
      "x": 29,
      "y": 21
    },
    {
      "x": 28,
      "y": 21
    },
    {
      "x": 31,
      "y": 21
    },
    {
      "x": 34,
      "y": 16
    },
    {
      "x": 34,
      "y": 17
    },
    {
      "x": 34,
      "y": 18
    },
    {
      "x": 34,
      "y": 19
    },
    {
      "x": 34,
      "y": 20
    },
    {
      "x": 35,
      "y": 21
    },
    {
      "x": 36,
      "y": 21
    },
    {
      "x": 37,
      "y": 21
    },
    {
      "x": 38,
      "y": 21
    },
    {
      "x": 38,
      "y": 20
    },
    {
      "x": 39,
      "y": 20
    },
    {
      "x": 35,
      "y": 20
    },
    {
      "x": 35,
      "y": 19
    },
    {
      "x": 35,
      "y": 18
    },
    {
      "x": 35,
      "y": 17
    },
    {
      "x": 35,
      "y": 16
    },
    {
      "x": 35,
      "y": 15
    },
    {
      "x": 36,
      "y": 15
    },
    {
      "x": 37,
      "y": 15
    },
    {
      "x": 38,
      "y": 15
    },
    {
      "x": 38,
      "y": 16
    },
    {
      "x": 39,
      "y": 16
    },
    {
      "x": 42,
      "y": 15
    },
    {
      "x": 42,
      "y": 16
    },
    {
      "x": 42,
      "y": 17
    },
    {
      "x": 42,
      "y": 18
    },
    {
      "x": 42,
      "y": 19
    },
    {
      "x": 42,
      "y": 20
    },
    {
      "x": 42,
      "y": 21
    },
    {
      "x": 43,
      "y": 21
    },
    {
      "x": 43,
      "y": 20
    },
    {
      "x": 43,
      "y": 19
    },
    {
      "x": 43,
      "y": 18
    },
    {
      "x": 43,
      "y": 17
    },
    {
      "x": 43,
      "y": 16
    },
    {
      "x": 43,
      "y": 15
    },
    {
      "x": 44,
      "y": 15
    },
    {
      "x": 45,
      "y": 15
    },
    {
      "x": 46,
      "y": 15
    },
    {
      "x": 47,
      "y": 15
    },
    {
      "x": 44,
      "y": 18
    },
    {
      "x": 45,
      "y": 18
    },
    {
      "x": 46,
      "y": 18
    },
    {
      "x": 44,
      "y": 21
    },
    {
      "x": 45,
      "y": 21
    },
    {
      "x": 46,
      "y": 21
    },
    {
      "x": 47,
      "y": 21
    },
    {
      "x": 50,
      "y": 16
    },
    {
      "x": 50,
      "y": 17
    },
    {
      "x": 51,
      "y": 16
    },
    {
      "x": 51,
      "y": 17
    },
    {
      "x": 51,
      "y": 18
    },
    {
      "x": 52,
      "y": 18
    },
    {
      "x": 53,
      "y": 18
    },
    {
      "x": 54,
      "y": 18
    },
    {
      "x": 54,
      "y": 19
    },
    {
      "x": 55,
      "y": 19
    },
    {
      "x": 55,
      "y": 20
    },
    {
      "x": 54,
      "y": 20
    },
    {
      "x": 54,
      "y": 21
    },
    {
      "x": 53,
      "y": 21
    },
    {
      "x": 52,
      "y": 21
    },
    {
      "x": 51,
      "y": 21
    },
    {
      "x": 50,
      "y": 20
    },
    {
      "x": 51,
      "y": 15
    },
    {
      "x": 52,
      "y": 15
    },
    {
      "x": 53,
      "y": 15
    },
    {
      "x": 54,
      "y": 15
    },
    {
      "x": 55,
      "y": 16
    }
  ]
}