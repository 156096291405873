import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// interfaces
import { IAppState } from '../../App.i';

// components
import { Container } from '../../components/container/Container';

// props
import { ExploreProps } from './ExploreProps';

// styles
import * as styles from './Explore.sass';

// template
export class ExploreComponent extends Component<ExploreProps, {}> {
  render() {
    let data: any;

    if (this.props.type == 'equipment') {
      data = this.props.equipment.find((equipmentItem: any) => {
        if (equipmentItem.url == this.props.name) {
          return true;
        }
      });
    } else {
      data = this.props.services.find((service: any) => {
        if (service.url == this.props.name) {
          return true;
        }
      });
    }
    if (!data) {
      return <Redirect to="/404" />;
    }

    const listItems = data.list.map((d: any) => <li key={d} dangerouslySetInnerHTML={{ __html: d }} />);

    return (
      <div className={classNames(styles.main, this.props.className)}>
        <Container>
          <div className={styles.exploreText}>
            <div className={styles.exploreTitle}>{data.name}</div>
            <div className={styles.orangeBar} />
            <div className={styles.exploreImage} style={{ backgroundImage: `url(${data.image2url})` }} />
            <p className={styles.exploreDescription} dangerouslySetInnerHTML={{ __html: data.description }} />
            <ul className={styles.list}>{listItems}</ul>
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state: IAppState): ExploreProps {
  return {
    equipment: state.equipment.equipment,
    services: state.services.services
  };
}

export const Explore: any = connect(mapStateToProps)(ExploreComponent);
