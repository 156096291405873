import * as classNames from 'classnames';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// interfaces
import { IAppState } from '../../App.i';
import {
  IBreadcrumbsOwnProps,
  IBreadcrumbsStateProps,
  IBreadcrumbsDispatchProps
} from './Breadcrumbs.i';

// components
import { Container } from '../../components/container/Container';

// styles
import * as styles from './Breadcrumbs.sass';

// props
type Props = IBreadcrumbsOwnProps &
  IBreadcrumbsStateProps &
  IBreadcrumbsDispatchProps;

// template
export class BreadcrumbsComponent extends Component<Props, {}> {
  render() {
    return (
      <div className={classNames(styles.breadcrumbs, this.props.className)}>
        <Container className={styles.breadcrumbsContainer}>
          <div className={styles.breadcrumbsNavContainer}>
            <a href="/">Home</a>
            {this.props.breadcrumbsItems.map(item => {
              return (
                <Fragment key={item.name}>
                  <img
                    className={styles.arrowIcon}
                    src="assets/breadcrumbs-arrow.svg"
                  />
                  <a href={item.url}>{item.text}</a>
                </Fragment>
              );
            })}
          </div>
          {/* <div className={styles.breadcrumbsSearchContainer}>
            <input
              type="search"
              className={styles.searchInput}
              id="searchInput"
              name="search"
              placeholder="Search entire site..."
            />
            <button
              id="searchButton"
              onClick={() => {
                const searchInput = (document.getElementById(
                  'searchInput'
                ) as HTMLInputElement).value
                  .split(' ')
                  .join('+');
                window.open(
                  `https://www.google.com/search?&q=${searchInput}+site%3Ahbstexas.com`,
                  '_blank'
                );
              }}
            >
              <img
                className={styles.searchIcon}
                src="assets/search-button.svg"
              />
            </button>
          </div> */}
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state: IAppState): Props {
  return {
    breadcrumbsItems: state.breadcrumbs.breadcrumbsItems
  };
}

export const Breadcrumbs: any = connect(mapStateToProps)(BreadcrumbsComponent);
