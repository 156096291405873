import classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// interfaces
import { IAppState } from '../../App.i';
import { IMenuOwnProps, IMenuStateProps, IMenuDispatchProps } from './Menu.i';

// styles
import * as styles from './Menu.sass';

// props
type Props = IMenuOwnProps & IMenuStateProps & IMenuDispatchProps;

// component
class MenuComponent extends Component<Props, {}> {
  render() {
    // console.log(this.props.menuItems);
    return (
      <nav className={classNames(styles.menu, this.props.className)}>
        <ul className={styles.menuList}>
          {this.props.menuItems.map(menuItem => {
            let linkMarkup = menuItem.active ? (
              <Link
                className={classNames(styles.menuListItemLink, styles.active)}
                to={menuItem.url}
              >
                {menuItem.text}
              </Link>
            ) : (
              <Link className={styles.menuListItemLink} to={menuItem.url}>
                {menuItem.text}
              </Link>
            );
            return (
              <li className={styles.menuListItem} key={menuItem.url}>
                {linkMarkup}
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

function mapStateToProps(state: IAppState): Props {
  return {
    menuItems: state.menu.menuItems.map(menuItem => {
      return {
        active: menuItem.name === state.menu.selectedMenuItem,
        text: menuItem.text,
        url: menuItem.url
      };
    })
  };
}

export const Menu: any = connect(mapStateToProps)(MenuComponent);
