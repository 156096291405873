import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// props
import { ResponsiveMenuProps } from './ResponsiveMenuProps';

// styles
import * as styles from './ResponsiveMenu.sass';

// template
export class ResponsiveMenu extends Component<ResponsiveMenuProps, {}> {
  render() {
    return (
      <div
        id="menuBackground"
        onClick={() => {
          const menuButton = document.getElementById('menuLinks');
          const menuBackground = document.getElementById('menuBackground');
          menuButton.style.width == '200px'
            ? menuButton.setAttribute('style', 'width: 0')
            : menuButton.setAttribute('style', 'width: 200px');
          menuBackground.style.width == '100vw'
            ? menuBackground.setAttribute('style', 'width: 0')
            : menuBackground.setAttribute('style', 'width: 100vw');
        }}
        className={classNames(styles.main, this.props.className)}
      >
        <div id="menuLinks" className={styles.menuLinks}>
          <a className={styles.menuLink} href="/">
            Home
          </a>
          <a className={styles.menuLink} href="about">
            About
          </a>
          <a className={styles.menuLink} href="services">
            Services
          </a>
          <a className={styles.menuLink} href="equipment">
            Equipment
          </a>
          <a className={styles.menuLink} href="events">
            Special Events
          </a>
          <a className={styles.menuLink} href="contact">
            Contact Us
          </a>
        </div>
      </div>
    );
  }
}
