import { useState } from 'react';

// clients
import { Client } from '../clients/client';

export const useContactForm = () => {
  // field state
  const [recipientId, setRecipientId] = useState('001');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event: Event) => {
    event.preventDefault();

    setIsSubmitting(true);

    const client = new Client({
      url: CONFIG.apiUrl
    });

    try {
      await client.createMessage({
        recipientId,
        name,
        email,
        phone,
        message
      });
      setIsComplete(true);
    } catch {
      setErrorMessage('Unable to submit contact form');
    }

    setIsSubmitting(false);
  };

  return {
    recipientId,
    setRecipientId,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    message,
    setMessage,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    isComplete,
    setIsComplete,
    errorMessage,
    setErrorMessage
  };
};
