// constants
import { SELECT_EQUIPMENT } from '../../constants';

// interfaces
import { ISelectEquipmentAction, ISelectEquipmentActionParams } from './selectEquipmentAction.i';

export function selectEquipmentAction(params: ISelectEquipmentActionParams): ISelectEquipmentAction {
  return {
    type: SELECT_EQUIPMENT,
    payload: {
      equipment: params.equipment
    }
  };
}
