import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// props
import { ContainerProps } from './ContainerProps';

// styles
import * as styles from './Container.sass';

// component
export class Container extends Component<ContainerProps, {}> {
  render() {
    return (
      <div className={classNames(styles.container, this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}