exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'Helvetica Neue LT Std';\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"assets/HelveticaNeueLTStd-Cn.otf\"); }\n\n.ContactSummary__contactSummary__2Kxrv {\n  padding-left: 75px; }\n  @media screen and (max-width: 1000px) {\n    .ContactSummary__contactSummary__2Kxrv {\n      padding-left: 0; } }\n\n.ContactSummary__contactSummaryTitle__3WuXQ {\n  color: black;\n  font-family: 'Roboto';\n  text-transform: uppercase;\n  font-size: 1.5em;\n  line-height: 20px; }\n  @media screen and (max-width: 1000px) {\n    .ContactSummary__contactSummaryTitle__3WuXQ {\n      margin-top: 30px; } }\n\n.ContactSummary__main__1NqIc {\n  font-family: Verdana;\n  margin-bottom: 80px; }\n  @media screen and (max-width: 1000px) {\n    .ContactSummary__main__1NqIc {\n      padding: 0 30px; } }\n\n.ContactSummary__orangeBar__rndV_ {\n  background: #f18621;\n  width: 50px;\n  height: 2px;\n  margin: 23px 0 10px 0; }\n  @media screen and (max-width: 1000px) {\n    .ContactSummary__orangeBar__rndV_ {\n      text-align: center;\n      display: inline-block;\n      margin: 23px 0 5px 0; } }\n\np a {\n  white-space: nowrap;\n  text-decoration: none;\n  color: black; }\n", ""]);

// exports
exports.locals = {
	"contactSummary": "ContactSummary__contactSummary__2Kxrv",
	"contactSummaryTitle": "ContactSummary__contactSummaryTitle__3WuXQ",
	"main": "ContactSummary__main__1NqIc",
	"orangeBar": "ContactSummary__orangeBar__rndV_"
};