// constants
import { SELECT_MENU_ITEM } from '../../constants';

// interfaces
import { ISelectMenuItemAction, ISelectMenuItemActionParams } from './selectMenuItemAction.i';

export function selectMenuItemAction(params: ISelectMenuItemActionParams): ISelectMenuItemAction {
  return {
    type: SELECT_MENU_ITEM,
    payload: {
      menuItem: params.menuItem
    }
  };
}
