// client library
import { Client } from '../../clients/client';

// constants
import { LIST_RECIPIENTS } from '../../constants';

// interfaces
import {
  IListRecipientsAction,
  IListRecipientsActionParams,
  IListRecipientsActionResult
} from './listRecipientsAction.i';
import { Dispatch } from 'redux';

export function listRecipientsAction(
  params: IListRecipientsActionParams
): IListRecipientsActionResult<Promise<IListRecipientsAction>> {
  return async (dispatch: Dispatch<IListRecipientsAction>) => {
    const client = new Client({
      url: CONFIG.apiUrl
    });
    const result = await client.listRecipients();

    return dispatch({
      type: LIST_RECIPIENTS,
      payload: {
        recipients: result.recipients
      }
    });
  };
}

//reducer for rec
//add reducer to app.tsx
//connect recipients reducer to ContactForm component
// -> mapStateToProp for recipients

//loader for loading recipients
