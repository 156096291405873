import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

import { Link, Element } from 'react-scroll';

// components
import { Container } from '../../components/container/Container';

// props
import { ServicesProps } from './ServicesProps';

// styles
import * as styles from './Services.sass';

// template
export class Services extends Component<ServicesProps, {}> {
  render() {
    return (
      <div className={classNames(styles.services, this.props.className)}>
        <Container>
          <div className={styles.serviceSelectors}>
            <div className={styles.serviceSelectorRow}>
              <Link
                to="trafficControl"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.serviceLink}
              >
                Traffic Control
              </Link>
              <span className={styles.serviceSelectorDivider}> | </span>
              <Link
                to="permanentSigns"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.serviceLink}
              >
                Permanent Signs
              </Link>
              <span className={styles.serviceSelectorDivider}> | </span>
              <Link
                to="trenchSafety"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.serviceLink}
              >
                Trench Safety
              </Link>
            </div>
            <div className={styles.serviceSelectorRow}>
              <Link
                to="pavementMarkings"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.serviceLink}
              >
                Pavement Markings
              </Link>
              <span className={styles.serviceSelectorDivider}> | </span>
              <Link
                to="training"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.serviceLink}
              >
                Training
              </Link>
            </div>
          </div>
          <div className={styles.serviceContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="trafficControl" className="element">
              <div id="trafficControl" className={styles.serviceTitle}>
                Traffic Control
              </div>
            </Element>
            <div
              className={classNames(
                styles.serviceImageContainer,
                styles.trafficControl
              )}
            >
              <div className={styles.slider1}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    The heart of our business. Highway Barricades and Services
                    team of experienced professionals are ready to assist with
                    all your traffic maintenance needs. We pride ourselves on
                    considering the details of your unique work zone setup to
                    offer the most effective traffic diversion strategy. From
                    Lane closures to detours to sidewalk closures. Let us help
                    plan your next project.
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a href="/services/traffic-control" className={styles.sliderButton}>
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.serviceContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="permanentSigns" className="element">
              <div id="permanentSigns" className={styles.serviceTitle}>
                Permanent Signs
              </div>
            </Element>
            <div
              className={classNames(
                styles.serviceImageContainer,
                styles.permanentSigns
              )}
            >
              <div className={styles.slider2}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    HBS provides permanent road signs and installation for
                    local, city, county and state government including street
                    signs, street name signs and pedestrian signage.
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a href="/services/permanent-signs" className={styles.sliderButton}>
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.serviceContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="trenchSafety" className="element">
              <div id="trenchSafety" className={styles.serviceTitle}>
                Trench Safety
              </div>
            </Element>
            <div
              className={classNames(
                styles.serviceImageContainer,
                styles.trenchSafety
              )}
            >
              <div className={styles.slider3}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    South Texas Trench Safety, a dba of HBS, rents, sells and
                    services trench boxes for shoring purposes. We are an
                    authorized distributor for GME™ and Plug-It Products™ and
                    offer a comprehensive line of shoring and excavation
                    equipment to safely and successfully complete your trench
                    work.
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a href="/services/trench-safety" className={styles.sliderButton}>
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.serviceContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="pavementMarkings" className="element">
              <div id="pavementMarkings" className={styles.serviceTitle}>
                Pavement Markings
              </div>
            </Element>
            <div
              className={classNames(
                styles.serviceImageContainer,
                styles.pavementMarkings
              )}
            >
              <div className={styles.slider4}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    Another cornerstone of our business, Pavement Markings, is a
                    large part of our portfolio. Road striping provides an
                    efficient way of directing both foot and motor traffic. We
                    are the best in the business at highway and road striping
                    and provide markings for crosswalks and parking lot spacing.
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a href="/services/pavement-markings" className={styles.sliderButton}>
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.serviceContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="training" className="element">
              <div id="training" className={styles.serviceTitle}>
                Training
              </div>
            </Element>
            <div
              className={classNames(
                styles.serviceImageContainer,
                styles.training
              )}
            >
              <div className={styles.slider5}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    Not only do we practice safe practices, we also teach them.
                    Our certified personnel are always willing to offer training
                    courses in traffic control and trench safety. Please
                    inquire, a minimum group size may be required.
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a href="/services/training" className={styles.sliderButton}>
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
