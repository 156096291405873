import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Component } from 'react';
import { Helmet } from 'react-helmet';

// actions
import {
  selectMenuItemAction,
  ISelectMenuItemActionParams,
  ISetBreadcrumbsActionParams,
  setBreadcrumbsAction
} from '../../actions';

// components
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { PrivacyPolicy } from '../../components/privacyPolicy/PrivacyPolicy';

// interfaces
import {
  IPrivacyPolicyPageOwnProps,
  IPrivacyPolicyPageStateProps,
  IPrivacyPolicyPageDispatchProps
} from './PrivacyPolicyPage.i';

// styles
import * as styles from './PrivacyPolicyPage.sass';

// props
type Props = IPrivacyPolicyPageOwnProps &
  IPrivacyPolicyPageStateProps &
  IPrivacyPolicyPageDispatchProps;

// component
class PrivacyPolicyPageComponent extends Component<Props, {}> {
  componentWillMount() {
    this.props.selectMenuItem({
      menuItem: 'privacy'
    });
    this.props.setBreadcrumbs({
      breadcrumbsItems: [
        { name: 'privacy', url: 'privacy', text: 'Privacy Policy' }
      ]
    });
  }
  render() {
    return (
      <div className={classNames(styles.privacyPolicy, this.props.className)}>
        <Helmet>
          <title>Privacy Policy</title>
          <meta
            name="description"
            content="Privacy Policy - Highway Barricades &amp; Services"
          />
          <meta
            name="keywords"
            content="highway,barricades,services,privacy,policy"
          />
        </Helmet>
        <Breadcrumbs />
        <PrivacyPolicy />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectMenuItem: (params: ISelectMenuItemActionParams) => {
      dispatch(selectMenuItemAction(params));
    },
    setBreadcrumbs: (params: ISetBreadcrumbsActionParams) => {
      dispatch(setBreadcrumbsAction(params));
    }
  };
}

export const PrivacyPolicyPage = connect(
  null,
  mapDispatchToProps
)(PrivacyPolicyPageComponent);
