exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AboutPage__about__vqA3l {\n  padding: 0 0 0 0;\n  background: #f5f5f5; }\n", ""]);

// exports
exports.locals = {
	"about": "AboutPage__about__vqA3l"
};