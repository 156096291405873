exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'Helvetica Neue LT Std';\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"assets/HelveticaNeueLTStd-Cn.otf\"); }\n\n.ErrorPage__errorPage__YYpii {\n  padding: 0 20px;\n  background: #f18621;\n  color: white;\n  font-family: \"Helvetica Neue LT Std\";\n  height: 70vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1.2em;\n  text-align: center; }\n", ""]);

// exports
exports.locals = {
	"errorPage": "ErrorPage__errorPage__YYpii"
};