import * as React from 'react';
import thunk from 'redux-thunk';
import { Helmet } from 'react-helmet';

import { Component } from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { BrowserRouter, Switch } from 'react-router-dom';

// pages
import { AboutPage } from './pages/about/AboutPage';
import { ErrorPage } from './pages/errorPage/ErrorPage';
import { EquipmentPage } from './pages/equipment/EquipmentPage';
import { ExplorePage } from './pages/explore/ExplorePage';
import { HomePage } from './pages/home/HomePage';
import { ServicesPage } from './pages/services/ServicesPage';
import { ContactPage } from './pages/contact/ContactPage';
import { TermsOfUsePage } from './pages/termsOfUse/TermsOfUsePage';
import { PrivacyPolicyPage } from './pages/privacyPolicy/PrivacyPolicyPage';
import { SpecialEventsPage } from './pages/specialEvents/SpecialEventsPage';

// reducers
import {
  equipmentReducer,
  servicesReducer,
  menuReducer,
  breadcrumbsReducer,
  listRecipientsReducer
} from './reducers';

// templates
import { MainTemplate } from './templates/main/MainTemplate';

// styles
import './App.sass';

const store = createStore(
  combineReducers({
    breadcrumbs: breadcrumbsReducer,
    equipment: equipmentReducer,
    recipients: listRecipientsReducer,
    services: servicesReducer,
    menu: menuReducer
  }),
  applyMiddleware(thunk)
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename="/">
          <MainTemplate>
            <Helmet titleTemplate="%s | HBS">
              titleTemplate="%s | HBS - Highway Barricades &amp; Services"
            </Helmet>
            <Switch>
              <Route path="/" component={HomePage} exact />
              <Route path="/about" component={AboutPage} />
              <Route path="/:type/:name" component={ExplorePage} />
              <Route path="/services" component={ServicesPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/equipment" component={EquipmentPage} />
              <Route path="/terms" component={TermsOfUsePage} />
              <Route path="/privacy" component={PrivacyPolicyPage} />
              <Route path="/events" component={SpecialEventsPage} />
              <Route component={ErrorPage} />
            </Switch>
          </MainTemplate>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
