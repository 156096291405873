import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// components
import { Container } from '../../components/container/Container';

// props
import { SpecialEventsProps } from './SpecialEventsProps';

// styles
import * as styles from './SpecialEvents.sass';

// template
export class SpecialEvents extends Component<SpecialEventsProps, {}> {
  render() {
    return (
      <div className={classNames(styles.main, this.props.className)}>
        <Container>
          <div className={styles.specialEventsText}>
            <div className={styles.specialEventsTitle}>Special Events</div>
            <div className={styles.orangeBar} />
            <div className={styles.specialEventsImage} />
            <p className={styles.specialEventsDescription}>
              We love to have fun! We have been honored to provide barricades
              for some of Corpus’ biggest events like Buc Days and Beach to Bay.
              Whether it’s a major race or just a big home celebration, we can
              help keep your guests and motorists safe with our special event
              traffic planning and barricade setup. We will work with you to
              evaluate your site and provide you with a complete traffic control
              plan as well as setup and take down of any needed equipment.
            </p>
            <ul className={styles.list}>
              <li className={styles.listItem}>Water-Filled Barrier Wall</li>
              <li className={styles.listItem}>Orange Mesh Roll-Up Fencing</li>
              <li className={styles.listItem}>Traffic Control Planning</li>
              <li className={styles.listItem}>
                Traffic Control Barricade Setup and Take Down
              </li>
            </ul>
          </div>
        </Container>
      </div>
    );
  }
}
