import { useEffect, useState } from 'react';

// clients
import { Client } from '../clients/client';

export const useRecipients = () => {
  // data state
  const [data, setData] = useState([]);

  // form state
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchRecipients = async () => {
    const client = new Client({
      url: CONFIG.apiUrl
    });

    try {
      const result = await client.listRecipients();
      setData(result.recipients);
    } catch (error) {
      setErrorMessage('Failed to load recipients');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchRecipients();
  }, []);

  return {
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    data,
    setData,
    fetchRecipients
  };
};
