exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Patua+One);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'Helvetica Neue LT Std';\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"assets/HelveticaNeueLTStd-Cn.otf\"); }\n\n.Quote__quote__3NWb4 {\n  font-family: Verdana;\n  padding: 100px 10px;\n  background: linear-gradient(0deg, rgba(255, 116, 0, 0.8), rgba(255, 216, 0, 0.8)), url(\"assets/corn.jpg\");\n  background-attachment: fixed;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  margin-top: 100px;\n  font-family: 'Patua One';\n  font-size: 3em;\n  color: white;\n  text-align: center;\n  text-transform: uppercase; }\n  @media screen and (max-width: 768px) {\n    .Quote__quote__3NWb4 {\n      padding: 100px 30px;\n      background-repeat: repeat; } }\n  @media screen and (max-width: 576px) {\n    .Quote__quote__3NWb4 {\n      font-size: 1.9em; } }\n", ""]);

// exports
exports.locals = {
	"quote": "Quote__quote__3NWb4"
};