// constants
import { SET_BREADCRUMBS } from '../../constants';

// interfaces
import {
  ISetBreadcrumbsAction,
  ISetBreadcrumbsActionParams
} from './setBreadcrumbsAction.i';

export function setBreadcrumbsAction(
  params: ISetBreadcrumbsActionParams
): ISetBreadcrumbsAction {
  return {
    type: SET_BREADCRUMBS,
    payload: {
      breadcrumbsItems: params.breadcrumbsItems
    }
  };
}
