import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Component } from 'react';
import { Helmet } from 'react-helmet';

// actions
import {
  selectMenuItemAction,
  ISelectMenuItemActionParams,
  ISetBreadcrumbsActionParams,
  setBreadcrumbsAction
} from '../../actions';

// components
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { TermsOfUse } from '../../components/termsOfUse/TermsOfUse';

// interfaces
import {
  ITermsOfUsePageOwnProps,
  ITermsOfUsePageStateProps,
  ITermsOfUsePageDispatchProps
} from './TermsOfUsePage.i';

// styles
import * as styles from './TermsOfUsePage.sass';

// props
type Props = ITermsOfUsePageOwnProps &
  ITermsOfUsePageStateProps &
  ITermsOfUsePageDispatchProps;

// component
class TermsOfUsePageComponent extends Component<Props, {}> {
  componentWillMount() {
    this.props.selectMenuItem({
      menuItem: 'terms'
    });
    this.props.setBreadcrumbs({
      breadcrumbsItems: [{ name: 'terms', url: 'terms', text: 'Terms of Use' }]
    });
  }
  render() {
    return (
      <div className={classNames(styles.termsOfUse, this.props.className)}>
        <Helmet>
          <title>Terms of Use</title>
          <meta
            name="description"
            content="Terms of Use - Highway Barricades &amp; Services"
          />
          <meta
            name="keywords"
            content="highway,barricades,services,terms,use,policy"
          />
        </Helmet>
        <Breadcrumbs />
        <TermsOfUse />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectMenuItem: (params: ISelectMenuItemActionParams) => {
      dispatch(selectMenuItemAction(params));
    },
    setBreadcrumbs: (params: ISetBreadcrumbsActionParams) => {
      dispatch(setBreadcrumbsAction(params));
    }
  };
}

export const TermsOfUsePage = connect(
  null,
  mapDispatchToProps
)(TermsOfUsePageComponent);
