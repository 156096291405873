import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Helmet } from 'react-helmet';

// actions
import {
  selectMenuItemAction,
  ISelectMenuItemActionParams,
  ISetBreadcrumbsActionParams,
  setBreadcrumbsAction
} from '../../actions';

// components
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Equipment } from '../../components/equipment/Equipment';
import { Container } from '../../components/container/Container';

// interfaces
import { IAppState } from '../../App.i';
import {
  IEquipmentPageOwnProps,
  IEquipmentPageStateProps,
  IEquipmentPageDispatchProps
} from './EquipmentPage.i';

// styles
import * as styles from './EquipmentPage.sass';

// props
type Props = IEquipmentPageOwnProps &
  IEquipmentPageStateProps &
  IEquipmentPageDispatchProps;

// component
class EquipmentPageComponent extends Component<Props, {}> {
  componentWillMount() {
    this.props.selectMenuItem({
      menuItem: 'equipment'
    });
    this.props.setBreadcrumbs({
      breadcrumbsItems: [
        { name: 'equipment', url: 'equipment', text: 'Equipment' }
      ]
    });
  }
  render() {
    return (
      <div className={classNames(styles.equipment, this.props.className)}>
        <Helmet>
          <title>Equipment</title>
          <meta
            name="description"
            content="Equipment - Highway Barricades &amp; Services"
          />
          <meta
            name="keywords"
            content="equipment,highway,barricades,services,channeling,devices,impact,attenuators,work,zone,signs,message,arrow,boards,lights,safety,apparel,accessories"
          />
        </Helmet>
        <Breadcrumbs />
        <Container>
          <Equipment />
        </Container>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectMenuItem: (params: ISelectMenuItemActionParams) => {
      dispatch(selectMenuItemAction(params));
    },
    setBreadcrumbs: (params: ISetBreadcrumbsActionParams) => {
      dispatch(setBreadcrumbsAction(params));
    }
  };
}

function mapStateToProps(state: IAppState) {
  return {
    equipment: state.equipment.equipment.map(equipment => {
      return {
        active: state.equipment.selectedEquipment === equipment.name,
        availability: equipment.availability,
        description: equipment.description,
        image1url: equipment.image1url,
        image2url: equipment.image1url,
        name: equipment.name,
        pricing: equipment.pricing
      };
    })
  };
}

export const EquipmentPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentPageComponent);
