import classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// props
import { LogoProps } from './LogoProps';

// styles
import * as styles from './Logo.sass';

// component
export class Logo extends Component<LogoProps> {
  render() {
    return (
      <a href="/" className={classNames(styles.logo, this.props.className)}>
        <img className={styles.navLogo} src="assets/HBS-Logo.svg" />
      </a>
    );
  }
}
