exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Logo__logo__-EIw7 {\n  color: #FFF;\n  max-height: 60px;\n  display: flex; }\n\n.Logo__navLogo__1JKcH {\n  max-height: 50px;\n  margin-bottom: 5px;\n  margin-top: 5px; }\n", ""]);

// exports
exports.locals = {
	"logo": "Logo__logo__-EIw7",
	"navLogo": "Logo__navLogo__1JKcH"
};