import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// components
import { Container } from '../../components/container/Container';

// props
import { PrivacyPolicyProps } from './PrivacyPolicyProps';

// styles
import * as styles from './PrivacyPolicy.sass';

// template
export class PrivacyPolicy extends Component<PrivacyPolicyProps, {}> {
  render() {
    return (
      <div className={classNames(styles.main, this.props.className)}>
        <Container>
          <div className={styles.title}>Privacy Policy</div>
          <div className={styles.orangeBar} />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto
            nihil qui nam. Veniam quibusdam, quaerat ad deserunt libero,
            similique, aperiam recusandae ut voluptatibus fuga nobis quos
            accusamus accusantium odit? Eaque.
          </p>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
            eos qui ratione voluptatem sequi nesciunt.
          </p>
          <p>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores et quas molestias excepturi sint occaecati cupiditate non
            provident, similique sunt in culpa qui officia deserunt mollitia
            animi, id est laborum et dolorum fuga.
          </p>
          <p>
            Itaque earum rerum hic tenetur a sapiente delectus, ut aut
            reiciendis voluptatibus maiores alias consequatur aut perferendis
            doloribus asperiores repellat.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto
            nihil qui nam. Veniam quibusdam, quaerat ad deserunt libero,
            similique, aperiam recusandae ut voluptatibus fuga nobis quos
            accusamus accusantium odit? Eaque.
          </p>
        </Container>
      </div>
    );
  }
}
