exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'Helvetica Neue LT Std';\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"assets/HelveticaNeueLTStd-Cn.otf\"); }\n\n.EquipmentPage__equipment__2lAyA {\n  font-family: 'Roboto';\n  background: #f5f5f5;\n  padding: 0 0 100px 0; }\n  @media screen and (max-width: 768px) {\n    .EquipmentPage__equipment__2lAyA {\n      padding: 0 0 0 0; } }\n", ""]);

// exports
exports.locals = {
	"equipment": "EquipmentPage__equipment__2lAyA"
};