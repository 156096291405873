exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);", ""]);

// module
exports.push([module.id, "a {\n  text-decoration: none;\n  color: white; }\n\nbutton {\n  border: none;\n  background: none;\n  padding: 0 0 0 10px;\n  margin: 0;\n  -webkit-margin-bottom: -4px; }\n  button:focus {\n    outline: none !important; }\n\n.Breadcrumbs__arrowIcon__h2WuH {\n  height: 50px;\n  margin-right: 10px; }\n\n.Breadcrumbs__breadcrumbs__31wDx {\n  font-family: 'Roboto';\n  font-size: 1.1em;\n  font-weight: 400;\n  height: 50px;\n  background-image: linear-gradient(#efad1e, #f18621);\n  color: white;\n  margin-bottom: 100px; }\n\n.Breadcrumbs__breadcrumbsContainer__2g52x {\n  display: flex;\n  justify-content: space-between; }\n  @media screen and (max-width: 1000px) {\n    .Breadcrumbs__breadcrumbsContainer__2g52x {\n      padding: 0 20px; } }\n  @media screen and (max-width: 576px) {\n    .Breadcrumbs__breadcrumbsContainer__2g52x {\n      display: grid;\n      grid-template-columns: 1fr;\n      justify-items: start; } }\n\n.Breadcrumbs__breadcrumbsNavContainer__1DlO9 {\n  display: flex;\n  align-items: center; }\n\n.Breadcrumbs__breadcrumbsSearchContainer__1CpsR {\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n  @media screen and (max-width: 576px) {\n    .Breadcrumbs__breadcrumbsSearchContainer__1CpsR {\n      display: none; } }\n\n.Breadcrumbs__searchIcon__13wom {\n  height: 30px;\n  -webkit-top: 1px; }\n  .Breadcrumbs__searchIcon__13wom:hover {\n    cursor: pointer; }\n\n.Breadcrumbs__searchInput__2r_l9 {\n  width: 200px;\n  margin: 0;\n  -webkit-appearance: none;\n  padding: 0 10px;\n  margin-right: -12px;\n  border-radius: 50px 0 0 50px;\n  position: relative;\n  top: -1px;\n  font-size: 0.7em;\n  font-family: 'Roboto';\n  height: 30px;\n  border: none; }\n\n::placeholder {\n  color: #e0e0e0; }\n", ""]);

// exports
exports.locals = {
	"arrowIcon": "Breadcrumbs__arrowIcon__h2WuH",
	"breadcrumbs": "Breadcrumbs__breadcrumbs__31wDx",
	"breadcrumbsContainer": "Breadcrumbs__breadcrumbsContainer__2g52x",
	"breadcrumbsNavContainer": "Breadcrumbs__breadcrumbsNavContainer__1DlO9",
	"breadcrumbsSearchContainer": "Breadcrumbs__breadcrumbsSearchContainer__1CpsR",
	"searchIcon": "Breadcrumbs__searchIcon__13wom",
	"searchInput": "Breadcrumbs__searchInput__2r_l9"
};