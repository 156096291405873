import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Component } from 'react';
import { Helmet } from 'react-helmet';

// actions
import {
  selectMenuItemAction,
  ISelectMenuItemActionParams,
  ISetBreadcrumbsActionParams,
  setBreadcrumbsAction
} from '../../actions';

// components
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Mission } from '../../components/mission/Mission';
import { Quote } from '../../components/quote/Quote';

// styles
import * as styles from './AboutPage.sass';

// component
class AboutPageComponent extends Component<IAboutPageProps, IAboutPageState, {}> {
  componentWillMount() {
    this.props.selectMenuItem({
      menuItem: 'about'
    });
    this.props.setBreadcrumbs({
      breadcrumbsItems: [{ name: 'about', url: 'about', text: 'About' }]
    });
  }
  render() {
    return (
      <div className={classNames(styles.about, this.props.className)}>
        <Helmet>
          <title>About</title>
          <meta
            name="description"
            content="About Highway Barricades &amp; Services"
          />
          <meta
            name="keywords"
            content="about,highway,barricades,services,traffic,control,company"
          />
        </Helmet>
        <Breadcrumbs />
        <Mission />
        <Quote />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectMenuItem: (params: ISelectMenuItemActionParams) => {
      dispatch(selectMenuItemAction(params));
    },
    setBreadcrumbs: (params: ISetBreadcrumbsActionParams) => {
      dispatch(setBreadcrumbsAction(params));
    }
  };
}

export const AboutPage = connect(
  null,
  mapDispatchToProps
)(AboutPageComponent);
