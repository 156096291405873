exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MainTemplate__main__1reRk {\n  font-family: Verdana; }\n", ""]);

// exports
exports.locals = {
	"main": "MainTemplate__main__1reRk"
};