import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

// components
import { Container } from '../../components/container/Container';

// props
import { QuoteProps } from './QuoteProps';

// styles
import * as styles from './Quote.sass';

// template
export class Quote extends Component<QuoteProps, {}> {
  render() {
    return (
      <div className={classNames(styles.quote, this.props.className)}>
        <Container>Where Safety Meets Satisfaction</Container>
      </div>
    );
  }
}
