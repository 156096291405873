// constants
import { SUBMIT_MESSAGE } from '../../constants';

// interfaces
import {
  ISubmitMessageAction,
  ISubmitMessageActionParams
} from './submitMessageAction.i';

export function submitMessageAction(
  params: ISubmitMessageActionParams
): ISubmitMessageAction {
  return {
    type: SUBMIT_MESSAGE,
    payload: {
      recipientId: params.recipientId,
      name: params.name,
      email: params.email,
      phone: params.phone,
      message: params.message
    }
  };
}
