import * as classNames from 'classnames';
import * as React from 'react';
import { Component, Fragment } from 'react';

// props
import { SignsStartProps } from './SignsStartProps';

// styles
import * as styles from './SignsStart.sass';

// signs
import { PavementMarkings } from '../../signs/PavementMarkings';
import { TrafficDevices } from '../../signs/TrafficDevices';
import { TrafficControl } from '../../signs/TrafficControl';
import { TrenchSafety } from '../../signs/TrenchSafety';
import { Signs } from '../../signs/Signs';
import { SpecialEvents } from '../../signs/SpecialEvents';

// interfaces
interface IPixel {
  x: number;
  y: number;
}

interface IState {
  count: number;
  pixels: IPixel[];
  selectedPixels: IPixel[];
  xOffset: number;
  yOffset: number;
}

// template
export class SignsStart extends Component<SignsStartProps, {}> {
  readonly state: IState = {
    count: 0,
    pixels: [],
    selectedPixels: [],
    xOffset: 0,
    yOffset: 0
  };

  componentDidMount() {
    const signsArray = [
      TrafficDevices,
      TrafficControl,
      Signs,
      SpecialEvents,
      TrenchSafety,
      PavementMarkings
    ];

    let sign = signsArray[this.state.count];
    this.switchSign(sign);

    setInterval(() => {
      sign = signsArray[this.state.count];
      this.switchSign(sign);
      if (this.state.count >= signsArray.length) {
        this.setState({ count: 0 });
      }
    }, 3000);
  }
  switchSign(sign?: any) {
    const pixels = [];
    for (let x = 0; x < 60; x++) {
      for (let y = 0; y < 30; y++) {
        pixels.push({
          x,
          y
        });
      }
    }

    const xOffset = sign.xOffset || 0;
    const yOffset = sign.yOffset || 0;
    const selectedPixels = sign.pixels.map((pixel: any) => ({
      x: pixel.x + xOffset,
      y: pixel.y + yOffset
    }));

    this.setState({
      count: this.state.count + 1,
      pixels,
      selectedPixels,
      xOffset,
      yOffset
    });
  }
  isSelected(pixel: any) {
    return this.state.selectedPixels.some(selectedPixel => {
      if (selectedPixel.x === pixel.x && selectedPixel.y === pixel.y) {
        return true;
      }
      return false;
    });
  }
  render() {
    return (
      <div className={classNames(styles.sign, this.props.className)}>
        <div className={styles.border}>
          <svg
            className={styles.bg}
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 3000 1500"
          >
            {this.state.pixels.map(pixel => (
              <svg
                key={`${pixel.x}x${pixel.y}`}
                x={pixel.x * 50 + 5}
                y={pixel.y * 50 + 5}
              >
                {!this.isSelected(pixel) && (
                  <Fragment>
                    <circle
                      className={styles.pixel}
                      cx="20"
                      cy="10"
                      r="10"
                    />
                    <circle
                      className={styles.pixel}
                      cx="10"
                      cy="29"
                      r="10"
                    />
                    <circle
                      className={styles.pixel}
                      cx="30"
                      cy="29"
                      r="10"
                    />
                  </Fragment>
                )}
                {this.isSelected(pixel) && (
                  <Fragment>
                    <circle
                      className={styles.selectedPixel}
                      cx="20"
                      cy="10"
                      r="12"
                    />
                    <circle
                      className={styles.selectedPixel}
                      cx="10"
                      cy="29"
                      r="12"
                    />
                    <circle
                      className={styles.selectedPixel}
                      cx="30"
                      cy="29"
                      r="12"
                    />
                    <circle
                      className={styles.bulb}
                      cx="20"
                      cy="10"
                      r="4"
                    />
                    <circle
                      className={styles.bulb}
                      cx="10"
                      cy="29"
                      r="4"
                    />
                    <circle
                      className={styles.bulb}
                      cx="30"
                      cy="29"
                      r="4"
                    />
                  </Fragment>
                )}
              </svg>
            ))}
          </svg>
          <div className={styles.sunroof} />
          <div className={styles.leftBox} />
          <div className={styles.rightBox} />
          <div className={styles.wire} />
          <div className={styles.leftPole} />
          <div className={styles.rightPole} />
        </div>
        <div className={styles.pole} />
      </div>
    );
  }
}
