import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import { Fade } from 'react-reveal';

// components
import { Container } from '../../components/container/Container';

// props
import { AffiliatesProps } from './AffiliatesProps';

// styles
import * as styles from './Affiliates.sass';

// template
export class Affiliates extends Component<AffiliatesProps, {}> {
  render() {
    return (
      <div className={classNames(styles.affiliates, this.props.className)}>
        <div className={styles.leftImage} />
        <Container className={styles.centerDiv}>
          <div className={styles.logoContainer}>
            <Fade>
              <img className={styles.logo} src="assets/agc-logo.svg" />
            </Fade>
            <Fade delay={100}>
              <img className={styles.logo} src="assets/abc-logo.svg" />
            </Fade>
            <Fade delay={200}>
              <img className={styles.logo} src="assets/wbe-certified.svg" />
            </Fade>
            <Fade delay={300}>
              <img className={styles.logo} src="assets/dbe-logo.svg" />
            </Fade>
            <Fade delay={400}>
              <img className={styles.logo} src="assets/mbe-certified.png" />
            </Fade>
            <Fade delay={500}>
              <img className={styles.logo} src="assets/sbe-certified.png" />
            </Fade>
            <Fade delay={600}>
              <img className={styles.logo} src="assets/hub-logo.svg" />
            </Fade>
            <Fade delay={700}>
              <img
                className={styles.logo}
                src="assets/united-corpus-christi.png"
              />
            </Fade>
          </div>
        </Container>
        <div className={styles.rightImage} />
      </div>
    );
  }
}
